<template>
  <div> 
    <section id="about">
      <div class="aboutWrap">
        <div class="main">
          <!-- <h4>Money Conversations + Intentional Actions = Successful Outcomes</h4> -->
           <img src="/images/moneyplusequals.png" />
          <p>How you talk about money matters. It impacts your quality of life and your wellbeing. These conversations build and important bridge between unhealthy silence and emotional interactions. When combined with intentional actions, these conversations help you become more confident and successful with money.</p>
          <p>Created by personal finance expert, Nathan Dungan, Money Sanity U<sup>&reg;</sup> offers unique insights and relatable examples&mdash;designed to generate meaningful conversations, actionable ideas and healthy habits.</p>
          <!-- <h4>Money conversations in the sane middle</h4> -->
          <img src="/images/saneMiddle.svg" alt="Money conversations in the sane middle">
        </div>
        <aside>
          <h4>How it works</h4>
          <img src="/images/video_frame.png" alt="watch our intro video" @click="showVideo('314794169')">
          <div class="button" data-btnText="Watch Introduction" data-btnTextHover="Play Video" @click="showVideo('314794169')">&nbsp;</div>
        </aside>
      </div>
    </section>
    <section id="details" v-if="signInType != 'sso'">
      <div>
        <h5>What it is</h5>
        <p>A subscription-based platform to address a wide variety of money issues using videos, conversation starters and activities. Learning tracks provide users access to tools and resources to increase financial balance and wellbeing.</p>
      </div>
      <div class="popular">
        <h5>Sample Topics</h5>
        <ul>
          <!-- <li @click="showVideo('304948663')" class="hasSample">Impact Investing</li> -->
          <li>Managing an Inheritance</li>
          <li>Money &amp; Relationships</li>
          <li @click="showVideo('107502285')" class="hasSample">Linking Your Money and Values</li>
          <li @click="showVideo('354575987')" class="hasSample">Kids &amp; Allowance</li>
          <li>Planning for Retirement</li>
          <li>The Cost of Owning a Pet</li>
        </ul>
        
      </div>
      <div class="cta">
        <h5>Get Started</h5>
        <p>Money Sanity U is available to family offices, multi-family offices and organizations to use with their family members, clients and/or employees. Contact us for more details and to arrange a demo.</p>
        <div class="buttonWrap">
          <a href="http://sharesavespend.com/contact-us" class="button" data-btnText="Get in touch" data-btnTextHover="Get in touch" target="_blank">&nbsp;</a>
        </div>
      </div>
    </section>
    <div id="modal" v-if="showVideoModal == true">
      <div id="modalContent" class="isVideo">
        <div class="closeBtn" @click="hideVideo()"></div>
        <div class='embed-container'>
          <iframe v-bind:src="videoPath" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ENV from '@/config';
import { usePlausible } from 'v-plausible/vue'

const { trackEvent, trackPageview } = usePlausible()

export default {
  name: 'Welcome',
  props: ['signInType'],
  data () {
    return {
        verNum: null,
        showVideoModal: false,
        videoPath: '',
        introVidId: '314794169',
        thisScreen: 'Standard Login Screen'
    }
  },

  mounted: function(){
    let qp = new URLSearchParams(location.search);
    if(qp.has('showIntro')) {
      this.videoPath = 'https://player.vimeo.com/video/' + this.introVidId +'?color=F47E20&title=0&byline=0&portrait=0&autoplay=0'
      this.showVideoModal = true
      trackEvent("Play Video", { props: {action: "Play", videoID: this.introVidId, onPage: "pre-login" }})
    }
    if(this.signInType === 'sso'){
      this.thisScreen = 'Medica SSO Login'
      // trackPageview({url: window.location.href + "loginSSO"})
    } else {
      // trackPageview({url: window.location.href + "login"})
    }
  },

  methods: {
    showVideo: function(vID){
      this.videoPath = 'https://player.vimeo.com/video/' + vID +'?color=F47E20&title=0&byline=0&portrait=0&autoplay=1'
      this.showVideoModal = true
      trackEvent("Play Video", { props: {action: "Play", videoID: this.vID, onPage: "pre-login" }})
    },
    hideVideo: function(){
      this.showVideoModal = false
    }
  }
}

</script>
