/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import Vue from 'vue';
import { createRouter, createWebHistory} from 'vue-router';

import { CreateTopic, CreateTrack, CreateUnit, Group, Member, IconList, SystemStats } from '@/components/admin';
import { NewPassword, ResetPassword, Profile, TrackList, Track, Topic, UserProgress, NotFound, Login, Terms, AllTracks, AllTopics, SSOAuth, AuthRedirect, FirstSignIn } from '@/components';
// import { AmplifyPlugin } from 'aws-amplify-vue';
import { Amplify } from 'aws-amplify';
import { Hub } from "@aws-amplify/core"
import { Auth } from "@aws-amplify/auth"
import AmplifyStore from '../store/store';
import { DynamoDB, CognitoIdentityServiceProvider } from 'aws-sdk'
// AWS.config.region = 'us-east-1';
import TrackOrder from '@/components/admin/components/Dashboard.TrackDisplay'
import Topics from '@/components/admin/components/Dashboard.Topics'
import Tracks from '@/components/admin/components/Dashboard.Tracks'
import Units from '@/components/admin/components/Dashboard.Units'
import Resources from '@/components/admin/components/Dashboard.Resources'
import Groups from '@/components/admin/components/Dashboard.Groups'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

// Vue.use(AmplifyPlugin);


let user;
let authRedirect = '/dashboard'

const routes = [
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            analytics: 'Pre Login Page'
        }
    },
    {
        path: '/new-user',
        name: 'FirstSignIn',
        component: FirstSignIn,
        meta: {
            analytics: 'First Time'
        }
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/auth/newpassword',
        name: 'NewPassword',
        component: NewPassword,
    },
    {
        path: '/auth/resetpassword',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/dashboard',
        name: 'TrackList',
        component: TrackList,
        meta: {
            requiresAuth: true,
            analytics: 'Post Login Home Page'
        }
    },
    {
        path: '/AllTopics',
        name: 'AllTopics',
        component: AllTopics,
        meta: {
            requiresAuth: true,
            analytics: 'All Topics'
        }
    },
    {
        path: '/AllTracks',
        name: 'AllTracks',
        component: AllTracks,
        meta: {
            requiresAuth: true,
            analytics: 'All Tracks'
        }
    },

    {
        path: '/track/:slug',
        name: 'Track',
        props: true,
        component: Track,
        meta: {
            requiresAuth: true,
            analytics: ':slug (Track)'
        }
    },

    {
        path: '/topic/:slug',
        name: 'Topic',
        props: true,
        component: Topic,
        meta: {
            requiresAuth: true,
            analytics: ':slug (Topic)'
        }
    },

    {
        path: '/myprogress',
        name: 'UserProgress',
        component: UserProgress,
        meta: { requiresAuth: true }
    },

    {
        path: '/admin/member/:sub',
        name: 'AdminMember',
        props: true,
        component: Member,
        meta: { requiresAuth: true }
    },

    {
        path: '/admin/createtopic',
        name: 'AdminCreateTopic',
        component: CreateTopic,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/iconlist',
        name: 'IconList',
        component: IconList,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/stats',
        name: 'SystemStats',
        component: SystemStats,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/updatetopic/:slug',
        name: 'AdminUpdateTopic',
        props: true,
        component: CreateTopic,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/createtrack',
        name: 'AdminCreateTrack',
        component: CreateTrack,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/updatetrack/:slug',
        name: 'AdminUpdateTrack',
        props: true,
        component: CreateTrack,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/createunit',
        name: 'AdminCreateUnit',
        component: CreateUnit,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/updateunit/:unitId',
        name: 'AdminUpdateUnit',
        props: true,
        component: CreateUnit,
        meta: { requiresAuth: true, requiresAdmin: true }
    },

    {
        path: '/admin/groups',
        name: 'Groups',
        component: Groups,
        meta: { requiresAuth: true }
    },

    {
        path: '/admin/group/:slug',
        name: 'AdminGroup',
        props: true,
        component: Group,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/layouts',
        name: 'TrackOrder',
        component: TrackOrder,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/topics',
        name: 'Topics',
        component: Topics,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/tracks',
        name: 'Tracks',
        component: Tracks,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/units',
        name: 'Units',
        component: Units,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/admin/resources',
        name: 'Resources',
        component: Resources,
        meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            analytics: 'Not Found Page'
        }
    },
    {
        path: '/medica',
        name: 'medica',
        component: SSOAuth,
        props: {
            ssoType: 'medica'
        },
        meta: {
            analytics: 'Signin Medica'
        }
    },
    {
        path: '/medica/:via',
        name: 'medicaDeep',
        component: SSOAuth,
        props: route => ({
            ssoType: 'medica',
            via: route.params.via
        }),
        meta: {
            analytics: 'Signin Medica'
        }
    },
    {
        path: '/medica/:via/:destination',
        name: 'medicaDeepDestination',
        component: SSOAuth,
        props: route => ({
            ssoType: 'medica',
            via: route.params.via,
            destination: route.params.destination
        }),
        meta: {
            analytics: 'Signin Medica'
        }
    },
    {
        path: '/fci',
        name: 'fiduciary',
        component: SSOAuth,
        props: {
            ssoType: 'fiduciary'
        },
        meta: {
            analytics: 'Signin Fiduciary'
        }
    },
    {
        path: '/fci/:via',
        name: 'fiduciaryDeep',
        component: SSOAuth,
        props: route => ({
            ssoType: 'fiduciary',
            via: route.params.via,
        }),
        meta: {
            analytics: 'Signin Fiduciary'
        }
    },
    {
        path: '/fci/:via/:destination',
        name: 'fiduciaryDeepDestination',
        component: SSOAuth,
        props: route => ({
            ssoType: 'fiduciary',
            via: route.params.via,
            destination: route.params.destination
        }),
        meta: {
            analytics: 'Signin Fiduciary'
        }
    },
    {
        path: '/authRedirect',
        name: 'authRedirect',
        component: AuthRedirect
    },
    // {
    //     path: '*',
    //     redirect: '/404'
    // }
]

Hub.listen(/.*/, async (data) => {
    let event = data.payload.event
    // console.log('Hub event ' + event)
    // console.log('Listening for all messages: ', data.payload.data)

    if (event === 'signOut') {
        user = null;
        AmplifyStore.commit('setUser', null);
        AmplifyStore.commit('private/setUserProgress', null);
        router.push({ path: '/' })
    }
    else if (event === 'signIn') {
        // console.log('SIGNED IN', authRedirect)
        user = await getUser();
        trackLogin(user);
        trackEvent(user, '500');
        router.push({ path: authRedirect })
    }
    else if (event === 'cognitoHostedUI') {
        user = await getUser();
        router.push({ path: authRedirect })
    }
});

function trackLogin(user) {
    let params = {
          TableName: `${AmplifyStore.state.ENV.tablePrefix}Users`,
          Key: { "user": user.attributes.sub },
          UpdateExpression: 'set logins = list_append(if_not_exists(logins, :empty_list), :logins)',
          ExpressionAttributeValues: { ":logins": [dayjs().utc().format()], ":empty_list": [] }
        }
    AmplifyStore.state.docClient.update(params, function(err, data) {
        if (err) console.log(err)
        return
    });
}

function trackEvent(user, loginType) {  
    let params = {
        TableName: `${AmplifyStore.state.ENV.tablePrefix}EventLog`,
        Item: {
            "time": dayjs().utc().format(),
            "event": loginType,
            "user": user.attributes.sub,
            "group": user.attributes["custom:groupSlug"],
            "detail": 'Role: ' + user.attributes["custom:role"],
        },
    }
    AmplifyStore.state.docClient.put(params, function(err, data) {
        if (err) {
            console.log(err)
        } 
    });
}

function getQueryVariable(query, variable) {
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return false
}

function authUser() {
    return Auth.currentCredentials()
        .then(credentials => {
            const tokens = Auth.essentialCredentials(credentials)
            const docClient = new DynamoDB.DocumentClient({ "credentials": tokens, "region": Amplify.Auth._config.region });
            const cognito = new CognitoIdentityServiceProvider({ "credentials": tokens, "region": Amplify.Auth._config.region })
            AmplifyStore.commit('set_docClient', docClient)
            AmplifyStore.commit('set_cognito', cognito)
        })
        .catch(err => { console.log('error in action', err); return err; })
}

function getUser() {
    return Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
            return authUser()
                .then(async(x) => {
                    AmplifyStore.commit('setUser', data);
                    await AmplifyStore.dispatch('private/getUserGroup')
                    await AmplifyStore.dispatch('private/getUserProgress')

                    let settings = "default"
                    if (AmplifyStore.state.private.group.settings) settings = AmplifyStore.state.private.group.settings
                    if (AmplifyStore.state.private.group.subGroups && AmplifyStore.state.private.userProgress.subGroupId) {
                        let subGroup = AmplifyStore.state.private.group.subGroups.find(sg => sg.id === AmplifyStore.state.private.userProgress.subGroupId)
                        if (subGroup) settings = subGroup.settings
                    }
                    // AmplifyStore.dispatch('private/getSettings')
                    if (!AmplifyStore.state.private.trackDisplay) {
                        let params = {
                            TableName: `${AmplifyStore.state.ENV.tablePrefix}Settings`,
                            Key: { "config": settings }
                        }

                        let getSettings = AmplifyStore.state.docClient.get(params).promise()
                        let finished = getSettings.then(res => {
                            AmplifyStore.commit('admin/setSettings', res.Item)
                            AmplifyStore.commit('private/setTrackDisplay', res.Item.trackDisplay)
                            AmplifyStore.commit('private/setTopics', res.Item.topics)
                            return data;
                        }).catch(err => {
                            return err
                        })
                        return finished
                    }

                    return data;
                });
        }
    }).catch((e) => {
        AmplifyStore.commit('setUser', null);
        return null
    });
}

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeResolve(async(to, from, next) => {
    // console.log('BEFORE RESOLVE - ', to)
    if (to.query.redirect) authRedirect = to.query.redirect
        // if (!user) user = await getUser();
    var trackerText = '';
    if (to.name == 'Track') {
        trackerText = 'track/' + to.params.slug
    } else if (to.name == 'Topic') {
        trackerText = 'topic/' + to.params.slug
    } else {
        trackerText = to.name
    }
    //console.log(trackerText)
    // Vue.analytics.trackView(trackerText, true)

    if (to.matched.some(record => record.meta.requiresAuth)) {
        user = await getUser();
        if (!user) {
            return next({
                path: '/',
                query: {
                    redirect: to.fullPath,
                }
            });
        }
    }

    // Restrict User level accounts from Admin area
    if (to.name.startsWith('Admin') && user.attributes["custom:role"] === 'user') {
        return next({ path: '/dashboard' })
    }

    // Restrict Manager level accounts from Track/Unit editing
    if (to.matched.some(record => record.meta.requiresAdmin) && user.attributes["custom:role"] !== 'admin') {
        return next({ path: '/dashboard' });
    }
    if (to.path === '/authRedirect') {
        let destination = getQueryVariable(to.hash, 'state')
        if (destination) authRedirect = '/' + getQueryVariable(to.hash, 'state')
        // console.log(authRedirect)
        return next()
    }
    // Redirect logged in users away from auth page
    if (to.path === '/') {
        user = await getUser();
        if (user) {
            return next({
                path: '/dashboard',
                query: {
                    redirect: to.fullPath,
                }
            });
        }
    }

    return next()
})

export default router