import slugify from 'slugify'
import { Auth } from 'aws-amplify'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const store = {
    namespaced: true,
    state: {
        allGroups: false,
        group: false,
        groupUserData: false,
        allLearningUnits: false,
        userList: false,
        managerList: false,
        settings: false,
        allSettings: false,
        allResources: false,
        allIcons: false,
        tempPassword: 'Fj6&HNg5'
    },
    actions: {
        getAllGroups: function({ rootState, commit }) {
            let params = { TableName: `${rootState.ENV.tablePrefix}UserGroups` }

            rootState.docClient.scan(params, function(err, data) {
                if (err) commit('set_allGroups', false)
                else commit('set_allGroups', data.Items)
            })
        },

        getGroupBySlug: async function({ rootState, commit }, slug) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}UserGroups`,
                Key: { slug: slug }
            }

            let setGroups = new Promise(function(resolve, reject) {
                rootState.docClient.get(params, function(err, data) {
                    if (err) {
                        commit('set_allGroups', false);
                        reject(err);
                    } else { commit('set_allGroups', data), resolve(data); }
                })
            });

            await setGroups
        },

        getUserList: function({ rootState, commit }) {
            let params = { UserPoolId: Auth.userPool.userPoolId }

            rootState.cognito.listUsers(params, function(err, data) {
                if (err) commit('set_userList', false)
                else commit('set_userList', data.Users)
            });
        },

        disableUser: function({ rootState, dispatch }, params) {
            let p = {
                UserPoolId: Auth.userPool.userPoolId,
                Username: params.username
            };

            rootState.cognito.adminDisableUser(p, function(err, data) {
                if (err) commit('set_userList', false)
                else dispatch('getUserList')
            });
        },

        batchDisableUsers: function({ rootState, dispatch }, params) {
            if (params.userList.length) {
                for (var i = 0; i < params.userList.length; i++) {
                    let p = {
                        UserPoolId: Auth.userPool.userPoolId,
                        Username: params.userList[i].sub
                    };

                    rootState.cognito.adminDisableUser(p, function(err, data) {
                        if (err) console.log('error', err)
                        else console.log('data', data)
                    });
                }
            }
        },

        enableUser: function({ rootState, dispatch }, params) {
            let p = {
                UserPoolId: Auth.userPool.userPoolId,
                Username: params.username
            };

            rootState.cognito.adminEnableUser(p, function(err, data) {
                if (err) commit('set_userList', false)
                else dispatch('getUserList')
            });
        },

        batchEnableUsers: function({ rootState, dispatch }, params) {
            if (params.userList.length) {

                for (var i = 0; i < params.userList.length; i++) {
                    let p = {
                        UserPoolId: Auth.userPool.userPoolId,
                        Username: params.userList[i].sub
                    };

                    rootState.cognito.adminEnableUser(p, function(err, data) {
                        if (err) console.log('error', err)
                        else console.log('data', data)
                    });
                }
            }
        },

        batchGetGroupUsers: function({ rootState, commit }, members) {
            let keys = members.map(m => { return { 'user': m.sub } })
            let params = {
                "RequestItems": {
                    [`${rootState.ENV.tablePrefix}Users`]: { "Keys": keys }
                }
            }

            let getMembers = rootState.docClient.batchGet(params).promise()

            getMembers
                .then(res => commit('set_groupUserData', res.Responses[`${rootState.ENV.tablePrefix}Users`]))
                .catch(err => console.log('UNABLE TO GET MEMBERS', err))
        },

        deleteUser: function({ rootState, dispatch }, params) {
            let cognitoParams = {
                UserPoolId: Auth.userPool.userPoolId,
                Username: params.username
            };

            let cognitoDeleteUser = new Promise(function(resolve, reject) {
                rootState.cognito.adminDeleteUser(cognitoParams, function(err, data) {
                    if (err) reject(err)
                    else resolve(params.username)
                });
            })

            cognitoDeleteUser.then(username => {
                let docClientParams = {
                    TableName: `${rootState.ENV.tablePrefix}Users`,
                    Key: { 'user': username }
                };
                // console.log('Deleteing', docClientParams)
                rootState.docClient.delete(docClientParams, function(err, data) {
                    if (err) console.log(err)
                    else {
                        console.log('deleted', data);
                        dispatch('getUserList');
                    }
                });
            }, err => {
                console.log(err)
            })

        },
        createUser: async function({ rootState, commit, dispatch }, user) {
            if (user.group) user.group = slugify(user.group.toLowerCase())

            let params = {
                UserPoolId: Auth.userPool.userPoolId,
                /* required */
                Username: user.username,
                /* required */
                DesiredDeliveryMediums: ["EMAIL"],
                ForceAliasCreation: false,
                // MessageAction: "SUPPRESS",
                TemporaryPassword: 'Fj6&HNg5', // password looks realistic
                UserAttributes: [{
                        Name: 'email',
                        Value: user.username
                    },
                    {
                        Name: 'email_verified',
                        Value: 'true'
                    },
                    {
                        Name: 'custom:groupSlug',
                        Value: user.group
                    },
                    {
                        Name: 'custom:role',
                        Value: user.role
                    }
                ]
            }

            if (user.first) {
                params.UserAttributes.push({
                    Name: 'given_name',
                    Value: user.first
                })
            }
            if (user.last) {
                params.UserAttributes.push({
                    Name: 'family_name',
                    Value: user.last
                })
            }

            let createCognitoUser = new Promise(function(resolve, reject) {
                rootState.cognito.adminCreateUser(params, function(error, data) {
                    if (error) reject(error)
                    else resolve(data)
                });
            });

            await createCognitoUser.then((data) => {
                let payload = {'slug': data.User.Username, 'group': user.group}
                dispatch('createUserProgress', payload)
                    // if (user.role === 'manager') dispatch('addToManagerCognitoGroup', data.User.Username)
                if (user.group) {
                    user.id = { "email": user.username, "sub": data.User.Username, "first": user.first, "last": user.last }
                    dispatch('addGroupMember', user)
                }
                commit('set_flashMessage', {
                    show: true,
                    level: 'success',
                    message: `User ${user.username} added`
                }, { root: true })
            }, error => {
                console.log(error)
                commit('set_flashMessage', {
                    show: true,
                    level: 'warn',
                    message: `User ${user.username} could not be added.`
                }, { root: true })
            });
        },

        resendInvite: async function({ rootState, commit, dispatch }, user) {
            if (user.group) user.group = slugify(user.group.toLowerCase())


            let params = {
                UserPoolId: Auth.userPool.userPoolId,
                Username: user.username,
                ForceAliasCreation: false,
                MessageAction: "RESEND",
                TemporaryPassword: 'Fj6&HNg5',
            }

            if (user.notification) {
                params.DesiredDeliveryMediums = ["EMAIL"]
            }

            let createCognitoUser = new Promise(function(resolve, reject) {
                rootState.cognito.adminCreateUser(params, function(error, data) {
                    if (error) reject(error)
                    else resolve(data)
                });
            });
        },

        createUserProgress: function({ rootState }, payload) {
            // console.log('Sub ' + payload.slug)
            // console.log('Org: ' + payload.group)
            let params = {
                TableName: `${rootState.ENV.tablePrefix}Users`,
                Item: {
                    "user": payload.slug,
                    "org": payload.group,
                    "activeUnits": [],
                    "activeTracks": []
                }
            };

            rootState.docClient.put(params, function(err, data) {
                if (err) console.log(err)
                    //else console.log(data)
            });
        },

        // addToManagerCognitoGroup: function({ rootState }, username) {
        //     let params = {
        //         GroupName: 'Manager',
        //         /* required */
        //         UserPoolId: Auth.userPool.userPoolId,
        //         /* required */
        //         Username: username /* required */
        //     }
        // 
        //     rootState.cognito.adminAddUserToGroup(params, function(err, data) {
        //         if (err) console.log(err, err.stack); // an error occurred
        //         //else     console.log(data);           // successful response
        //     });
        // },

        createGroup: function({ rootState }, params) {
            params.Item.slug = slugify(params.Item.title.toLowerCase());
            params.Item.createdAt = dayjs().utc().format();

            let p = {
                TableName: `${rootState.ENV.tablePrefix}UserGroups`,
                Item: params.Item
            }
            new Promise(function(resolve, reject) {
                rootState.docClient.put(p, function(err, data) {
                    if (err) reject(err)
                    else resolve(data)
                })
            });
        },

        addGroupMember: function({ dispatch }, user) {
            if (!user.members) user.members = []
            user.members.push(user.id)
            // console.log(user)
            let manager = user.manager
            if (typeof manager === 'string') manager = [manager]
            if (!manager) manager = []
            if (user.role === 'manager') manager.push(user.username)

            user.UpdateExpression = 'set members = :val, manager = :man'
            user.ExpressionAttributeValues = { ":val": user.members, ":man": manager }

            dispatch('updateGroupMembers', user)
        },

        removeGroupMember: function({ dispatch }, user) {
            let members = user.members.filter((m) => {
                return m.email !== user.email
            })
            user.members = members

            user.UpdateExpression = 'set members = :val'
            user.ExpressionAttributeValues = { ":val": user.members }

            dispatch('updateGroupMembers', user)
        },

        updateGroupMembers: function({ rootState, dispatch }, group) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}UserGroups`,
                Key: { 'slug': group.group },
                UpdateExpression: group.UpdateExpression,
                ExpressionAttributeValues: group.ExpressionAttributeValues
            };
            // console.log(group)
            rootState.docClient.update(params, function(err, data) {
                if (err) console.log(err)
                else dispatch('getGroupBySlug', group.group)
            });
        },

        // listUsersInCognitoGroup: function({ rootState, commit }, groupName) {
        //     let params = {
        //         GroupName: 'Manager',
        //         /* required */
        //         UserPoolId: Auth.userPool.userPoolId,
        //         /* required */
        //         // Limit: 1
        //     };
        //     rootState.cognito.listUsersInGroup(params, function(err, data) {
        //         if (err) commit('setManagerList', false); // an error occurred
        //         else commit('setManagerList', data.Users); // successful response
        //     });
        // },

        getAllLearningUnits: async function({ rootState, commit }) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}LearningUnits`
            }

            let getAllLearningUnits = new Promise(function(resolve, reject) {
                rootState.docClient.scan(params, function(error, data) {
                    if (error) reject(error)
                    else resolve(data)
                });
            });

            await getAllLearningUnits.then((data) => {
                commit('set_allLearningUnits', data.Items);
            }, error => {
                commit('set_allLearningUnits', false);
            });
        },

        getAllSettings: async function({ rootState, commit }) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}Settings`
            }
            let getSettings = rootState.docClient.scan(params).promise()

            await getSettings
                .then(data => commit('setAllSettings', data.Items))
                .catch(err => commit('setAllSettings', null))
        },
        getAllResources: function({ rootState, commit }) {
            let params = { TableName: `${rootState.ENV.tablePrefix}Resources` }

            rootState.docClient.scan(params, function(err, data) {
                if (err) commit('set_allResources', false)
                else commit('set_allResources', data.Items)
            })
        },
        getAllIcons: async function({ rootState, commit }) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}Icons`
            }
            let getIcons = rootState.docClient.scan(params).promise()

            await getIcons
                .then(data => commit('setAllIcons', data.Items))
                .catch(err => commit('setAllIcons', null))
        },
    },
    mutations: {
        set_allGroups(state, allGroups) {
            state.allGroups = allGroups
        },
        set_group(state, group) {
            state.group = group
        },
        set_groupUserData(state, users) {
            state.groupUserData = users
        },
        set_allLearningUnits(state, allLearningUnits) {
            state.allLearningUnits = allLearningUnits
        },
        set_userList(state, users) {
            state.userList = users
        },
        setManagerList(state, managers) {
            state.managerList = managers
        },
        setSettings(state, settings) {
            state.settings = settings
        },
        setAllSettings(state, allSettings) {
            state.allSettings = allSettings
        },
        set_allResources(state, allResources) {
            state.allResources = allResources
        },
        setAllIcons(state, allIcons) {
            state.allIcons = allIcons
        },
    }
}

export default store