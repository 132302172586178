// import { version } from '../../package'
const ENV = (function() {
    let CONFIG = {};
    const host = window.location.hostname;
    const appVersion = require('../../package.json').version || '0';
    // dev:   http://localhost:8080/
    // demo:  http://msu-web-demo.s3-website.us-east-2.amazonaws.com

    CONFIG.version = appVersion;
    CONFIG.appName = 'MoneySanityU';

    switch (host) {
        //case 'localhost':
        case 'moneysanityu.com':
        case 'my.moneysanityu.com':
            CONFIG.tablePrefix = 'MSU_live_';
            CONFIG.name = 'Production';
            CONFIG.identityPoolId = 'us-east-2:74d04271-86b9-4e97-ac8b-6fc9b8ae2a00'
            CONFIG.region = 'us-east-2'
            CONFIG.userPoolId = 'us-east-2_OBAZDxFPd'
            CONFIG.userPoolWebClientId = '3sp8atm45bgra0u6489m0084dt'
            CONFIG.mandatorySignIn = false
            CONFIG.bucket = 'msu-client-assets'
            CONFIG.newFlagTime = 90
            CONFIG.newFlagUnit = 'days'
            CONFIG.oAuthDomain = 'moneysanityu.auth.us-east-2.amazoncognito.com'
            CONFIG.oAuthAfterSignIn = 'https://my.moneysanityu.com/dashboard/'
            CONFIG.oAuthAfterSignOut = 'https://my.moneysanityu.com/'
            CONFIG.oAuthBeforeSignInMedica = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Medica&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInfci = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=fci&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            console.log('Version: ' + appVersion)
            break;

        case 'test.moneysanityu.com':
            CONFIG.tablePrefix = 'MSU_live_';
            CONFIG.name = 'Test';
            CONFIG.identityPoolId = 'us-east-2:74d04271-86b9-4e97-ac8b-6fc9b8ae2a00'
            CONFIG.region = 'us-east-2'
            CONFIG.userPoolId = 'us-east-2_OBAZDxFPd'
            CONFIG.userPoolWebClientId = '3sp8atm45bgra0u6489m0084dt'
            CONFIG.mandatorySignIn = false
            CONFIG.bucket = 'msu-client-assets'
            CONFIG.newFlagTime = 90
            CONFIG.newFlagUnit = 'days'
            CONFIG.oAuthDomain = 'moneysanityu.auth.us-east-2.amazoncognito.com'
            CONFIG.oAuthAfterSignIn = 'https://my.moneysanityu.com/dashboard/'
            CONFIG.oAuthAfterSignOut = 'https://my.moneysanityu.com/'
            CONFIG.oAuthBeforeSignInMedica = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Medica&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInfci = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=fci&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            console.log('Version: ' + appVersion)
            break;

        case 'backup.moneysanityu.com':
            CONFIG.tablePrefix = 'MSU_live_';
            CONFIG.name = 'Backup';
            CONFIG.identityPoolId = 'us-east-2:74d04271-86b9-4e97-ac8b-6fc9b8ae2a00'
            CONFIG.region = 'us-east-2'
            CONFIG.userPoolId = 'us-east-2_OBAZDxFPd'
            CONFIG.userPoolWebClientId = '3sp8atm45bgra0u6489m0084dt'
            CONFIG.mandatorySignIn = false
            CONFIG.bucket = 'msu-client-assets'
            CONFIG.newFlagTime = 90
            CONFIG.newFlagUnit = 'days'
            CONFIG.oAuthDomain = 'moneysanityu.auth.us-east-2.amazoncognito.com'
            CONFIG.oAuthAfterSignIn = 'https://my.moneysanityu.com/dashboard/'
            CONFIG.oAuthAfterSignOut = 'https://my.moneysanityu.com/'
            CONFIG.oAuthBeforeSignInMedica = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Medica&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInfci = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=fci&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            console.log('Version: ' + appVersion)
            break;

        // case 'test-my.moneysanityu.com':
        //     CONFIG.tablePrefix = 'MSU_dev_';
        //     CONFIG.name = 'Test';
        //     CONFIG.identityPoolId = 'us-east-1:6b838de4-3ad9-46b7-9e32-46596ad99656'
        //     CONFIG.region = 'us-east-1'
        //     CONFIG.userPoolId = 'us-east-1_wonJ9VS7D'
        //     CONFIG.userPoolWebClientId = '3qeklesq8gh0dd3m45c1q762s9'
        //     CONFIG.mandatorySignIn = false
        //     CONFIG.bucket = 'curious-msu-test'
        //     CONFIG.newFlagTime = 30
        //     CONFIG.newFlagUnit = 'days'
        //     CONFIG.oAuthDomain = 'msudev.auth.us-east-1.amazoncognito.com'
        //     CONFIG.oAuthAfterSignIn = 'https://test-my.moneysanityu.com/authRedirect/'
        //     CONFIG.oAuthAfterSignOut = 'https://test-my.moneysanityu.com/'
        //     CONFIG.oAuthBeforeSignInMedica = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Medica&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInfci = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=fci&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInAuth0 = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=auth0&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInSalesForce = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=SalesForce&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     console.log('XXXX DEVELOPMENT XXXX - Version: ' + version)
        //     break;

        // case 'localhost':
        //     CONFIG.tablePrefix = 'MSU_dev_';
        //     CONFIG.name = 'Development';
        //     CONFIG.identityPoolId = 'us-east-1:6b838de4-3ad9-46b7-9e32-46596ad99656'
        //     CONFIG.region = 'us-east-1'
        //     CONFIG.userPoolId = 'us-east-1_wonJ9VS7D'
        //     CONFIG.userPoolWebClientId = '3qeklesq8gh0dd3m45c1q762s9'
        //     CONFIG.mandatorySignIn = false
        //     CONFIG.bucket = 'curious-msu-test'
        //     CONFIG.newFlagTime = 6
        //     CONFIG.newFlagUnit = 'hours'
        //     CONFIG.oAuthDomain = 'msudev.auth.us-east-1.amazoncognito.com'
        //     CONFIG.oAuthAfterSignIn = 'http://localhost:8080/authRedirect'
        //     CONFIG.oAuthAfterSignOut = 'http://localhost:8080/'
        //     CONFIG.oAuthBeforeSignInMedica = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=auth0&redirect_uri=http://localhost:8080/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInfci = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=fci&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInAuth0 = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=auth0&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     CONFIG.oAuthBeforeSignInSalesForce = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=SalesForce&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
        //     console.log('XXXX DEVELOPMENT on LOCALHOST XXXX - Version: ' + appVersion)
        //     break;

        case 'localhost':
            CONFIG.tablePrefix = 'MSU_live_';
            CONFIG.name = 'Development';
            CONFIG.identityPoolId = 'us-east-2:74d04271-86b9-4e97-ac8b-6fc9b8ae2a00'
            CONFIG.region = 'us-east-2'
            CONFIG.userPoolId = 'us-east-2_OBAZDxFPd'
            CONFIG.userPoolWebClientId = '3sp8atm45bgra0u6489m0084dt'
            CONFIG.mandatorySignIn = false
            CONFIG.bucket = 'msu-client-assets'
            CONFIG.newFlagTime = 90
            CONFIG.newFlagUnit = 'days'
            CONFIG.oAuthDomain = 'moneysanityu.auth.us-east-2.amazoncognito.com'
            CONFIG.oAuthAfterSignIn = 'https://my.moneysanityu.com/dashboard/'
            CONFIG.oAuthAfterSignOut = 'https://my.moneysanityu.com/'
            CONFIG.oAuthBeforeSignInMedica = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Medica&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInfci = 'https://moneysanityu.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=fci&response_type=TOKEN&client_id=3sp8atm45bgra0u6489m0084dt&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            console.log('Version: ' + appVersion)
            break;

        default:
            CONFIG.tablePrefix = 'MSU_dev_';
            CONFIG.name = 'Development';
            CONFIG.identityPoolId = 'us-east-1:6b838de4-3ad9-46b7-9e32-46596ad99656'
            CONFIG.region = 'us-east-1'
            CONFIG.userPoolId = 'us-east-1_wonJ9VS7D'
            CONFIG.userPoolWebClientId = '3qeklesq8gh0dd3m45c1q762s9'
            CONFIG.mandatorySignIn = false
            CONFIG.bucket = 'curious-msu-test'
            CONFIG.newFlagTime = 30
            CONFIG.newFlagUnit = 'minutes'
            CONFIG.oAuthDomain = 'msudev.auth.us-east-1.amazoncognito.com'
            CONFIG.oAuthAfterSignIn = 'http://localhost:8080/authRedirect'
            CONFIG.oAuthAfterSignOut = 'http://localhost:8080/medica'
            CONFIG.oAuthBeforeSignInMedica = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=auth0&redirect_uri=http://localhost:8080/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInfci = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=fci&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInAuth0 = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=auth0&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            CONFIG.oAuthBeforeSignInSalesForce = 'https://msudev.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=SalesForce&redirect_uri=https://test-my.moneysanityu.com/authRedirect&response_type=TOKEN&client_id=3qeklesq8gh0dd3m45c1q762s9&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile'
            console.log('XXXX DEFAULT XXXX - Version: ' + appVersion)
            break;
    }

    return CONFIG
})()

export default ENV