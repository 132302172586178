<template>
      <div id="adminNav" v-if="this.user.attributes['custom:role'] === 'admin'">
        <router-link tag="div" to="/admin/layouts" class="tab">Content &amp; Layout</router-link>
        <router-link tag="div" to="/admin/topics" class="tab">Manage Topics</router-link>
        <router-link tag="div" to="/admin/tracks" class="tab">Manage Tracks</router-link>
        <router-link tag="div" to="/admin/units" class="tab">Manage Units</router-link>
        <router-link tag="div" to="/admin/resources" class="tab">Manage Resources</router-link>
        <router-link tag="div" to="/admin/iconlist" class="tab">Icons</router-link>
        <router-link tag="div" to="/admin/groups" class="tab">Organizations &amp; Users</router-link>
        <router-link tag="div" to="/admin/stats" class="tab">Statistics</router-link>
      </div>
</template>

<script>
import AmplifyStore from '@/store/store'

export default {
  name: 'AdminNav',
  data () {
    return {
    }
  },
  created () {
    AmplifyStore.dispatch('admin/getAllSettings')
  },
  methods: {
  },
  computed: {
    user() { return AmplifyStore.state.user },
  }
}
</script>

<style scoped>

</style>
