<template>
<div :style="cssVars">
    <div id="introSection" class="fullWidth">
      <div class="headerBorder"></div>
      <section id="topicOverview" class="bodySpacer verticalSpacer">
        <div v-if="!topic">
          <h3>Uh-oh we can't find that topic...</h3>
        </div>
        <div v-if="topic" class="banner" :style="cssBgImage"></div>
        <div v-if="topic" class="topicDesc">
          <h3>{{topic.title}}</h3>
          <div v-html="topic.content"></div>
        </div>
      </section>
    </div>
    <div id="allTracks" class="bodySpacer verticalSpacer" v-if="topic">
      <section id="trackList" class="grid"
        :class="[ user.attributes['custom:groupSlug'] === 'demo' ? 'isDemo' : '' ]"
        >
        <div class="trackListHeader">({{topicTracks.length}}) Tracks
          <!-- <router-link tag="a" to="/AllTracks" >View All Tracks</router-link> -->
        </div>
        <TrackTile v-for="track in topicTracks" :track="track" :key="track.slug" />
      </section>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import TrackTile from '@/components/TrackTile'

export default {
  name: 'Topic',
  components: { TrackTile },
  props: ['slug'],
  data () {
    return {
      lastTrack: false,
      displayGrid: true,
    }
  },
  mounted () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    if(!this.userProgress) AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)

    if (this.user && this.user.attributes['custom:role']!='admin'){
      // this.$plausible.trackPageview()
    }
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    }
  },
  computed: {
    user() { return AmplifyStore.state.user },
    tracks() { return AmplifyStore.state.private.allTracks },
    topics() { return AmplifyStore.state.private.topics },
    topic() {
      if(this.topics){
        let topic = this.topics.find( t => t.slug === this.slug )
        if(topic) return topic
      }
      return
    },
    userProgress() { return AmplifyStore.state.private.userProgress },
    topicTracks() {
      if(this.topic && this.topic.tracks && this.tracks){
        return this.topic.tracks.map( slug => {
            return this.tracks.find( t => t.slug === slug )
          })
      }

      return []
    },
    cssVars() {
      return {
        '--track-primary': this.topic.primaryColor,
        '--track-secondary': this.topic.secondaryColor
      }
    },
    cssBgImage() {
      return {
        'background-image': 'url(/images/topics/' + this.topic.banner +')'
      }
    }
  }
}
</script>

<style lang="scss" scoped >
  .headerBorder {
    background-color: var(--track-primary);
  }
  #topicOverview {
    min-height: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    border-bottom: 6px solid var(--big-border-color);
  }
  .banner {
    background: #ffffff bottom left no-repeat;
    position: absolute;
    top: 0; right: 0; bottom: 0; left:0;
  }
  .topicDesc {
    padding: 2rem;
    max-width: 33.33%;
  }

  @media screen and (max-width: 550px) { 
    #topicOverview {
      display: block;
      min-height: 0;
    }
    .banner {
      background-size: 500px;
      height: 140px;
      position: relative;
      top: unset; right: unset; bottom: unset; left: unset;
    }
    .topicDesc {
      padding: 0;
      max-width: 100%;
      margin: 0;
      padding: 2rem;
      background: var(--track-secondary);
      color: var(--color-white);
    }
  }
</style>
