<template>
  <div class="bodySpacer verticalSpacer admin display-flex" v-bind:class="unitDisabled ? 'hidden-unit' : ''">
    <div id="adminHead">
      <div>
        <h2>
          <span v-if="!unitId">Create New Unit</span>
          <span v-else>Update Unit : {{unitTitle}}</span>
        </h2>
        <div class="subHead usage" v-if="localUnit.foundIn && localUnit.foundIn.values">
          <span v-if="inExistingTracks.length == 0">Not in any tracks</span>
          <span v-if="inExistingTracks.length >= 1" >Used in: </span>
          <span class="foundIn" v-for="slug in inExistingTracks">
            <router-link :to="{ name: 'AdminUpdateTrack', params: { slug: slug }}">{{slug}}</router-link>
          </span>
        </div>

      </div>
      <div class="inputWrap">
        <label for="unitDisabled"><input type="checkbox" name="unitDisabled" @click="toggleUnitDisplay()" v-model="unitDisabled"> <span>Disable Unit?</span></label>
      </div>
    </div>
    <section id="createLeft">
        <div class="createLeftHeader" :class="{'new': isNew, 'update': isUpdated}">
          <h6>Manage Content</h6>
          <div class="actions">
            <label v-if="!isNew && !unitMarkNew"><input type="checkbox" v-model="unitMarkUpdated"> <span>Mark Updated</span></label>
            <label v-if="!isNew && !unitMarkUpdated"><input type="checkbox" v-model="unitMarkNew"> <span>Mark New</span></label>
          </div>
        </div>

      <div class="flex-wrap">
        <div class="inputWrap flex-half">
          <label for="unitTitle">Unit Title</label>
          <input type="text" v-model="unitTitle" placeholder="Unit title"/>
        </div>
        <div class="inputWrap flex-half">
          <label for="unitType">Type of Unit</label>
          <select name="unitType" v-model="unitType">
            <option disabled value="">Please select one</option>
            <option>Conversation</option>
            <!-- <option>Discussion Starter</option> -->
            <option>Activity</option>
            <!-- <option>Worksheet</option> -->
            <option>Resource</option>
            <option>Resources</option>
            <option>Video</option>
          </select>
        </div>
      </div>

      <div class="inputWrap">
        <label>Description Text</label>
        <QuillEditor theme="snow" v-model:content="unitContent" content-type="html" toolbar="essential" />
      </div>

      <h6>Media</h6>
      <div class="flex-wrap">
        <div class="inputWrap flex-half" v-if="unitType === 'Video'">
          <label for="unitVideo">Vimeo Video ID</label>
          <div class="example">Example: https://player.vimeo.com/video/<strong>107511144</strong></div>
          <input name="unitVideo" type="text" v-model="unitLink" placeholder="#########"/>
        </div>
        <div class="inputWrap uploadWrap flex-half" v-if="unitType != 'Video'">
          <label class="flex-full" for="">Embed an Image</label>
          <SSSImage v-if="unitImage" :imagePath="unitImage" style="max-width: 100%"></SSSImage>
          <input class="fileInput" type="file" name="embedImage" id="embedImage" ref="embedImage" v-on:change="getAttachedImage()"/>
          <label for="embedImage">Choose Image</label>
        </div>
        <div class="inputWrap uploadWrap flex-half" v-if="unitType != 'Video'">
          <label class="flex-full" for="">Attach a File</label>
          <input class="fileInput" type="file" id="file" name="file" ref="file" v-on:change="getAttachedFile()"/>
          <label for="file">Choose Attachment</label>
        </div>
      </div>

      <div class="buttonWrap">
        <div class="button" v-if="!unitId" @click="createUnit()" data-btnText="Create" data-btnTextHover="Save">&nbsp;</div>
        <div class="button" v-if="unitId" @click="updateUnit()" data-btnText="Update" data-btnTextHover="Save">&nbsp;</div>
      </div>
    </section>
    <!-- <aside>
      <h3>Linked to the following tracks:</h3>

      <div v-if="localUnit.foundIn && localUnit.foundIn.values.length < 2 && localUnit.foundIn.values[0] == 'null'">
        <i>Currently not used in any tracks</i>
      </div>

    </aside> -->

    <div id="preview">
      <h6>Unit Preview</h6>
      <div class="createPreview">
      <div class="unit open">
        <div class="unitTop">
            <div class="unitLeft">
              <h5>
                  <svg v-if="unitType === 'Conversation'" viewBox="0 0 100 100">
                    <path d="M81.64,90.06c-.53,0-1.07-.11-1.57-.32l-28.35-12.15c-.57,.02-1.13,.03-1.71,.03C12.9,77.62,5.11,59.22,5.11,43.78S12.9,9.94,50,9.94s44.89,18.4,44.89,33.84c0,11.35-4.17,20.01-12.42,25.78l3.09,15.73c.29,1.46-.26,2.95-1.41,3.88-.72,.59-1.62,.89-2.52,.89Zm-29.16-20.49c.54,0,1.08,.11,1.58,.32l22.2,9.51-2.15-10.97c-.33-1.67,.44-3.37,1.92-4.23,7.32-4.25,10.88-10.93,10.88-20.42s-3.3-15.74-10.1-20c-6.18-3.88-15.2-5.84-26.79-5.84s-20.61,1.96-26.79,5.84c-6.8,4.26-10.1,10.8-10.1,20s3.3,15.74,10.1,20c6.19,3.88,15.2,5.84,26.79,5.84,.6,0,1.18-.02,1.77-.03l.6-.02s.07,0,.1,0Z" />
                  </svg>
                  <svg v-if="unitType === 'Video'" viewBox="0 0 100 100">
                    <path d="M13.81,96.6c-.81,0-1.62-.21-2.35-.63-1.45-.84-2.35-2.39-2.35-4.07V8.97c0-1.68,.89-3.23,2.35-4.07,1.45-.83,3.25-.83,4.7,0L87.99,46.37c1.45,.84,2.35,2.39,2.35,4.07s-.89,3.23-2.35,4.07L16.16,95.97c-.72,.42-1.54,.63-2.35,.63ZM18.51,17.11V83.77l57.73-33.33L18.51,17.11Z" />
                  </svg>
                  <svg v-if="unitType === 'Activity'" viewBox="0 0 100 100">
                    <path d="M38.53,73.82L4.16,39.45c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l32.58,32.58,27.34-27.51L35.14,8.47c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l34.26,34.26c.31,.44,.46,.58,.59,.92l19.85,50.57c.36,.93,.15,1.98-.56,2.69-.7,.7-1.75,.93-2.68,.57l-50.74-19.55c-.33-.13-.63-.32-.87-.57Zm31.72-28.35l-25.46,25.61,29.77,11.47,7.34-7.39-11.65-29.69Zm13.66,34.8l-4.22,4.26,6.95,2.67-2.72-6.93ZM38.73,43.84L19.25,24.36c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l19.48,19.48c.98,.98,.98,2.57,0,3.55-.98,.98-2.57,.98-3.55,0Z" />
                    <path d="M91.05,95.1c-.42,0-.85-.08-1.26-.24l-50.74-19.55c-.46-.18-.87-.44-1.22-.79L3.46,40.16c-.66-.66-1.03-1.54-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l31.87,31.87,25.93-26.09L34.43,9.18c-.66-.66-1.03-1.55-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l34.38,34.4c.08,.12,.16,.22,.22,.3,.18,.24,.35,.47,.49,.84l19.84,50.55c.51,1.29,.2,2.77-.78,3.76-.67,.68-1.57,1.04-2.49,1.04Zm-51.82-21.99c.15,.15,.33,.26,.52,.34l50.75,19.55c.56,.22,1.19,.08,1.61-.34,.42-.43,.55-1.06,.33-1.62l-19.85-50.57c-.04-.11-.07-.16-.22-.36-.06-.08-.13-.17-.2-.28L37.99,5.63c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l33.2,33.2-28.75,28.93L7.01,36.61c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l34.37,34.37Zm49.15,15.83l-10.5-4.04,6.38-6.44,4.12,10.47Zm-6.92-4.81l3.4,1.31-1.33-3.39-2.06,2.08Zm-6.66-.42l-31.81-12.26,27.62-27.79,12.45,31.72-8.26,8.32Zm-28.23-13.02l27.73,10.68,6.42-6.47-10.85-27.66-23.3,23.44Zm-6.07-25.12c-.9,0-1.8-.34-2.48-1.03L18.55,25.07c-.66-.66-1.03-1.54-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l19.48,19.48c.66,.66,1.03,1.54,1.03,2.48s-.37,1.82-1.03,2.48c-.68,.68-1.58,1.03-2.48,1.03Zm-1.07-2.44c.59,.59,1.55,.59,2.14,0,.29-.29,.44-.67,.44-1.07s-.16-.78-.44-1.07L22.1,21.52c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l19.48,19.48Z" />
                  </svg>
                  <svg v-if="unitType === 'Resource' || unitType === 'Resources'" viewBox="0 0 100 100">
                    <path d="M51.12,64.47H27.93c-13.76,0-24.95-11.19-24.95-24.95S14.17,14.58,27.93,14.58h23.19c13.76,0,24.95,11.19,24.95,24.95s-11.19,24.95-24.95,24.95ZM27.93,22.58c-9.35,0-16.95,7.6-16.95,16.95s7.6,16.95,16.95,16.95h23.19c9.35,0,16.95-7.6,16.95-16.95s-7.6-16.95-16.95-16.95H27.93Z" />
                    <path d="M72.07,85.42h-23.19c-13.76,0-24.95-11.19-24.95-24.95s11.19-24.95,24.95-24.95h23.19c13.76,0,24.95,11.19,24.95,24.95s-11.19,24.95-24.95,24.95Zm-23.19-41.9c-9.35,0-16.95,7.6-16.95,16.95s7.6,16.95,16.95,16.95h23.19c9.35,0,16.95-7.6,16.95-16.95s-7.6-16.95-16.95-16.95h-23.19Z" />
                  </svg>
                  {{unitType}}
                </h5>
              <h3>{{unitTitle}}</h3>
            </div>
            <div class="unitRight">
              <div class="check">
                <svg width="40px" height="30px" viewBox="0 0 40 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g class="vector" transform="translate(-1269.000000, -2114.000000)" fill="#FFFFFF">
                            <g transform="translate(1173.000000, 2035.000000)">
                                <g transform="translate(72.000000, 50.000000)">
                                    <path d="M57.9686755,30.0306863 L37.5138059,50.4831987 L30.0234565,42.9946262 C28.6485211,41.6203778 26.4120381,41.6203778 25.0371026,42.9946262 C23.6542991,44.3767387 23.6542991,46.6121041 25.0371026,47.9863525 L35.0176784,57.9698052 C35.7080967,58.6539804 36.6070173,59 37.5138059,59 C38.4127265,59 39.3195151,58.6539804 40.0119004,57.9698052 L62.9628974,35.0224126 C64.3457009,33.6403001 64.3457009,31.4127988 62.9628974,30.0306863 C61.5879619,28.6564379 59.3514789,28.6564379 57.9686755,30.0306863 Z" id="checkmark"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
              </div>
            </div>
          </div>
        <div class="unitSub">
          <div class="unitLeft">
            <div v-html="unitContent" class="unitDescription"></div>
            <!-- <SSSImage v-if="localUnit.attachment" :imagePath="localUnit.attachment"></SSSImage> -->
            <div v-if="localUnit.link" class='embed-container'>
              <iframe v-bind:src="'https://player.vimeo.com/video/' + localUnit.link + '?color=F47E20&title=0&byline=0&portrait=0'" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
            </div>

          </div>
          <div class="unitRight">
            <a v-if="localUnit.attachment" class="attachment">
              <span class="text">Download {{localUnit.type}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import uuid from 'uuid'
import { Storage } from 'aws-amplify'
import AmplifyStore from '@/store/store'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'CreateTrack',
  props: ['unitId'],
  components: {
    QuillEditor
  },
  data () {
    return {
      localUnit: false,
      unitTitle: '',
      unitContent: '',
      unitType: '',
      unitLink: '',
      unitFile: false,
      unitImage: false,
      unitDisabled: false,
      unitMarkUpdated: false,
      unitMarkNew: false,
      unitUpdateDate: null,
      customToolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'clean']
          ]
    }
  },
  created () {
    if(this.unitId) this.getUnit()
    // this.getFile()
    // this.listFiles()
  },
  methods: {
    toggleUnitDisplay: function() {
      this.unitDisabled = !this.unitDisabled
    },
    listFiles: function() {
      Storage.list('')
        .then(result => console.log(result))
        .catch(err => console.log(err));
    },
    getFile: function() {
      Storage.get('Atest2.pdf')
        .then(result => console.log(result))
        .catch(err => console.log(err));
    },
    getAttachedFile: function() {
      this.unitFile = this.$refs.file.files[0];
    },
    getAttachedImage: function() {
      this.unitImage = this.$refs.embedImage.files[0];
    },
    uploadFile: function(file, unitId){
      //console.log(file)
      Storage.put(`learningUnits/${unitId}/${file.name}`, file, {
          contentType: file.type
      })
        .then (result => console.log(result))
        .catch(err => console.log(err));
    },
    getUnit: function() {
      let that = this
      let table = `${this.ENV.tablePrefix}LearningUnits`
      let params = {
        RequestItems: {}
      }
      params.RequestItems[table] = { Keys: [ { "unitId": this.unitId } ] }

      this.docClient.batchGet(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          if(data.Responses.hasOwnProperty(table)){
            that.localUnit = data.Responses[table][0]
            that.unitTitle = that.localUnit.title
            that.unitContent = that.localUnit.content
            that.unitType = that.localUnit.type
            that.unitLink = that.localUnit.link
            that.unitFile = that.localUnit.unitFile
            that.unitDisabled = that.localUnit.unitDisabled
            that.unitUpdateDate = that.localUnit.unitUpdateDate
            if(that.isUpdated){
              that.unitMarkUpdated = true
              // console.log("today is less than 30 days from the target date")
            }
            else {
              that.unitMarkUpdated = false
            } 
            
          }
        }
      })
    },
    createUnit: function() {
      let that = this;
      let item = this.setUnitParams()

      item.Item.unitId = uuid.v1()
      item.Item.createdAt = dayjs().utc().format()
      item.Item.unitUpdateDate = null

      if(this.unitFile) {
        this.uploadFile(this.unitFile, item.Item.unitId)
        item.Item.attachment = this.unitFile.name
      }

      if(this.unitImage) {
        this.uploadFile(this.unitImage, item.Item.unitId)
        item.Item.embedImage = this.unitImage.name
      }

      let params = {
        TableName : `${this.ENV.tablePrefix}LearningUnits`,
        Item: item.Item
      };

      this.docClient.put(params, function(err, data) {
        if (err) {
          console.log(err)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'warn',
            header: 'Create Error',
            message: 'Could not create new unit. Please try again.'
          })
        } else {
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Unit Created!',
            message: 'Unit: ' + that.unitTitle
          });
        }
      });
    },
    updateUnit: function() {
      let that = this;
      let time = dayjs().utc().format()
      let unit = this.setUnitParams()
          unit.Item.unitId = this.localUnit.unitId
          unit.Item.foundIn = this.localUnit.foundIn

      if(this.unitFile) {
        this.uploadFile(this.unitFile, unit.Item.unitId)
        unit.Item.attachment = this.unitFile.name
      }

      if(this.unitImage) {
        this.uploadFile(this.unitImage, unit.Item.unitId)
        unit.Item.embedImage = this.unitImage.name
      }

      if(this.unitMarkUpdated){
        unit.Item.unitUpdateDate = time;
        this.updateTracks(time)
      }
      if(!this.unitMarkUpdated){
        unit.Item.unitUpdateDate = null;
        this.updateTracks(null)
      }

      if(this.unitMarkNew){
        unit.Item.createdAt = time
        this.updateTracks(time)
      }
      let params = {
        TableName : `${this.ENV.tablePrefix}LearningUnits`,
        Item: unit.Item
      };

      this.docClient.put(params, function(err, data) {
        if (err) {
          console.log(err)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'warn',
            header: 'Update Failed',
            message: 'Could not update unit. Please try again.'
          })
        } else {
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Updates Saved!',
            message: 'Unit: ' + that.unitTitle
          });
        }
      });
    },
    updateTracks: function(updateDate){
      // localUnit.foundIn.values
       for (var i = 0; i < this.localUnit.foundIn.values.length; i++) {
         var params = {
           TableName: `${this.ENV.tablePrefix}Tracks`,
           Key: {
             'slug': this.localUnit.foundIn.values[i]
           },
           UpdateExpression: 'set updateContentDate = :d',
           ExpressionAttributeValues:{
             ":d": updateDate
           }
         }
         this.docClient.update(params, function(err, data) {
           if(err) {
             console.log(err)
           } else {
            //  console.log("Updated track")
           }
         })
       }
    },
    setUnitParams: function() {
      let params = {
        "Item": {
          "title": this.unitTitle,
          "content": this.unitContent,
          "type": this.unitType,
	        "link": this.unitLink || null,
          "attachment": this.localUnit.attachment || null,
          "foundIn": this.docClient.createSet(['null']),
          "createdAt": this.localUnit.createdAt,
          "unitDisabled": this.unitDisabled || false,
          "unitMarkUpdated": this.unitMarkUpdated || false
        }
      }

      return params
    }
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    allLearningUnits() { return AmplifyStore.state.admin.allLearningUnits },
    isNew() {
      if(this.localUnit && dayjs().diff(this.localUnit.createdAt, this.ENV.newFlagUnit) <= this.ENV.newFlagTime) {
        return true;
      }
      return false;
    },
    isUpdated() {
      if(this.localUnit.unitUpdateDate && dayjs().diff(this.localUnit.unitUpdateDate, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
        return true;
      }
      return false;
    },
    inExistingTracks() {
      const inTracks = this.localUnit.foundIn.values.filter(t => t != 'null' && t != null);
      return inTracks;
    }
  }
}
</script>

<style scoped>

  .error{
    border: 1px solid red;
    background-color: red
  }
</style>
