<template>
  <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="adminBlock">
        <h2>Resources &amp; Downloads</h2>

        <div class="upload flex-wrap">
            <div class="inputWrap flex-half">
                <label for="">Display Name</label>
                <input type="text" v-model="displayName">
            </div>
            <div class="inputWrap uploadWrap flex-half">
                <label class="flex-full" for="">File</label>
                <input class="fileInput" type="file" id="file" name="file" ref="file" v-on:change="getAttachedFile()"/>
                <label for="file"><span v-if="!theFile">Choose File</span><span v-else>{{theFile.name}}</span></label>
            </div>
            <div class="inputWrap flex-half">
              <label for="">Description</label>
              <input type="text" placeholder="short description" v-model="description">
            </div>
            <div class="inputWrap flex-half">
                <label for="">Assign to</label>
                <select name="" id="" v-if="allGroups" v-model="forGroups">
                  <option selected value="all">All</option>
                  <option v-for="g in sortedGroups" v-bind:key="g.slug" :value="g.slug">Org: {{g.title}}</option>
                  <option disabled>  ──────────  </option>
                  <option v-for="s in allSettings" v-bind:key="s.config" :value="s.config">Layout: {{s.config}}</option>
                </select>
            </div>
            <div class="inputWrap">
              <label> &nbsp; </label>
              <button @click="createResource">Add</button>
            </div>
        </div>

        <table class="linkTable unitList" v-if="allResources">
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Description</th>
              <th>File Name</th>
              <th>Created</th>
              <th>Assigned To</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="r in allResources" v-bind:key="r.resourceId" @click="$router.push({ name: 'AdminUpdateUnit', params: {unitId: unit.unitId} })" v-bind:class="unit.unitDisabled ? 'disabled' : ''"> -->
            <tr v-for="r in sortedResources" v-bind:key="r.resourceId">
              <td><strong>{{r.displayName}}</strong></td>
              <td>{{r.description}}</td>
              <td @click="showResource(r.resourceName)">{{r.resourceName}}</td>
              <td>{{formatDateTime(r.createdAt)}}</td>
              <td>{{r.forGroups}}</td>
              <td><button class="small" @click="deleteResource(r.resourceId)">Delete</button></td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import uuid from 'uuid'
import AmplifyStore from '@/store/store'
import { Storage, Logger } from 'aws-amplify';
import AdminNav from '@/components/admin/components/AdminNav'
import * as dayjs from 'dayjs'


export default {
  name: 'Dashboard.Resources',
  components: {AdminNav},
  data () {
    return {
        displayName: '',
        description: '',
        theFile: false,
        forGroups: "all",
    }
  },
  created () {
    // Initialize admin
    // if(this.user.attributes["custom:role"] === "admin"){
    //   AmplifyStore.dispatch('admin/getAllLearningUnits')
    // }
    AmplifyStore.dispatch('admin/getAllGroups'),
    AmplifyStore.dispatch('admin/getAllResources')

  },
  methods: {
    formatDateTime(input){
      return dayjs(input).format("MM/DD/YY");
    },
    getAttachedFile: function() {
      this.theFile = this.$refs.file.files[0];
    },
    uploadFile: function(file){
      //console.log(file)
      Storage.put(`resources/${file.name}`, file, {
          contentType: file.type
      })
        .then (result => console.log(result))
        .catch(err => console.log(err));
    },
    setParams: function() {
      let params = {
        "Item": {
            "displayName": this.displayName,
            "resourceName": this.theFile.name,
            "forGroups": this.forGroups,
            "description": this.description,
            "fileStatus": "active",
        }
      }

      return params
    },
    createResource: function() {
        let that = this;
        let item = this.setParams()
        
        item.Item.resourceId = uuid.v1()
        item.Item.createdAt = dayjs().utc().format();

        this.uploadFile(this.theFile)

        let params = {
            TableName : `${this.ENV.tablePrefix}Resources`,
            Item: item.Item
        };

        // console.log(params)

      this.docClient.put(params, function(err, data) {
        if (err) {
          console.log(err)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'warn',
            header: 'Create Error',
            message: 'Could not create resource. Please try again.'
          })
        } else {
          AmplifyStore.dispatch('admin/getAllResources')
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Resource Added!',
            message: 'File: ' + that.theFile.name
          });
        }
        that.displayName = ''
        that.description = '';
        that.theFile = false;
        that.forGroups = "all";
      });
    },
    deleteResource: function(rID){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}Resources`,
        Key: {'resourceId': rID},
        UpdateExpression: 'set fileStatus = :val',
        ExpressionAttributeValues: { ":val": "disabled" }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getAllResources')
        }
      });
    },
    showResource: function(file) {
      Storage
        .get(`resources/${file}`, {download: true})
        .then(res => {
          const newBlob = new Blob([res.Body], { "Content-Type": res.ContentType} )
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob, file);
              return;
          }
          const data = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = data;
          link.download = `${file}`;
          link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
          // link.click();

          setTimeout(function() {
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100)
        })
        .catch(err => console.log(err));
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    docClient() { return AmplifyStore.state.docClient },
    allGroups() { return AmplifyStore.state.admin.allGroups },
    allResources() { return AmplifyStore.state.admin.allResources },
    allSettings() { return AmplifyStore.state.admin.allSettings },
    sortedGroups () {
      let copy = this.allGroups.slice()
      return copy.sort(function(a, b) {
        var textA = a.title.toLowerCase();
        var textB = b.title.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    sortedResources () {
      let filtered = this.allResources.filter((r) => r.fileStatus === 'active')
      return filtered.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
    }
  }
}
</script>


