<template>
  <footer>
    <div class="bodySpacer">
        <nav class="userNav">
            <div class="links">
                <router-link :to="{name: 'Terms', params: {}}">Terms of Use</router-link>
                <a href="http://www.sharesavespend.com/contact-us">Contact Us</a>
                <!-- <router-link v-if="user && (user.attributes['custom:role']=='admin' || user.attributes['custom:role']=='manager')" tag="a" to="/admin" class="hideMobile">
                    Admin
                </router-link> -->
            </div>
        </nav>
        <p>All content &copy;{{currentYear}}, <a href="http://www.sharesavespend.com">Share Save Spend&reg;</a>, LLC All rights reserved.
          <span>&nbsp; Version: {{ENV.version}}</span>
        </p>
    </div>

  </footer>
</template>

<script>
import AmplifyStore from '../store/store'
import * as dayjs from 'dayjs'

export default {
  name: 'Footer',
  data () {
    return {
      imagePath: false,
      currentYear: dayjs().format('YYYY')
    }
  },
  async mounted() {
    // this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  created () {
  },
  methods: {
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
  }
}
</script>

<style scoped>
  /* .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  } */
</style>
