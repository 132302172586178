<template>
  <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="adminBlock">
        <div class="adminHeader">
          <h2>Tracks</h2>
          <router-link to="/admin/createtrack" v-slot="{href, route, navigate}">
              <button :href="href" @click="navigate" class="add">
                Create Track
              </button>
          </router-link>
        </div>
        <table class="linkTable trackList" v-if="allTracks">
          <thead>
            <tr>
              <th>Status:</th>
              <th>Name:</th>
              <th>Audience:</th>
              <th>Created:</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="track in activeTracks" v-bind:key="track.slug" @click="$router.push({ name: 'AdminUpdateTrack', params: {slug: track.slug} })" v-bind:class="track.trackDisabled ? 'disabled' : ''">
              <td class="name"><span v-if="track.trackDisabled">Disabled</span></td>
              <td class="name">{{track.title}}</td>
              <td class="audience">{{track.audience}}</td>
              <td class="created">{{formatDateTime(track.createdAt)}}</td>
            </tr>
          </tbody>
          <tbody>
            <tr v-for="track in inactiveTracks" v-bind:key="track.slug" @click="$router.push({ name: 'AdminUpdateTrack', params: {slug: track.slug} })" v-bind:class="track.trackDisabled ? 'disabled' : ''">
              <td class="name"><span v-if="track.trackDisabled">Disabled</span></td>
              <td class="name">{{track.title}}</td>
              <td class="audience">{{track.audience}}</td>
              <td class="created">{{formatDateTime(track.createdAt)}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import AdminNav from '@/components/admin/components/AdminNav'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'Dashboard.Tracks',
  components: {AdminNav},
  data () {
    return {}
  },
  created () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
  },
  unmounted () {
    AmplifyStore.commit('private/setAllTracks', false)
  },
  methods: {
    formatDateTime(input){
      return dayjs(input).format("MM/DD/YY");
    }
  },
  computed: {
    allTracks() { return AmplifyStore.state.private.allTracks },
    // sortedTracks(){
    //   if(this.allTracks){
    //     return this.allTracks.sort((a,b) =>{
    //       return dayjs().utc(b.createdAt).diff(dayjs().utc(a.createdAt))
    //     });
    //   }
    //   return []
    // },
    sortedTracks () {
      let copy = Array.from(this.allTracks).slice()
      return copy.sort(function(a, b) {
        if(a.title){
          var textA = a.title.toLowerCase();
        } else {
          var textA = ''
        }
        if(b.title){
          var textB = b.title.toLowerCase();
        } else {
          var textB = ''
        }
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    activeTracks () {
      return this.sortedTracks.filter( t => { return !t.trackDisabled })
    },
    inactiveTracks () {
      return this.sortedTracks.filter( t => { return t.trackDisabled })
    }
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }

  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
