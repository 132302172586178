<template>
  <div class="bodySpacer verticalSpacer admin display-flex" v-bind:class="localTopic.disabled ? 'hidden-track' : ''">

    <div id="adminHead">
      <h2>
        <span v-if="!slug">Create New Topic</span>
        <span v-else><strong>Edit Topic:</strong> {{localTopic.title}}</span>
      </h2>
      <div class="inputWrap flex-third">
              <div class="toggle">
                <button @click="toggleTrackDisplay('active')" v-if="localTopic.disabled">Activate</button>
                <button @click="toggleTrackDisplay('disabled')" v-if="!localTopic.disabled">Deactivate</button>
              </div>
      </div>
    </div>
    <section id="createLeft">
      <h6>Topic Details <label> <span>Featured:</span> <input type="checkbox" v-model="localTopic.featured"> </label></h6>
      <div class="inputWrap" v-if="!slug">
        <label for="">Topic Title</label>
        <input type="text" v-model="localTopic.title" placeholder="Try to make it engaging" v-bind:class="titleDuplicated() ? 'error' : ''"/>
      </div>


      <div class="inputWrap">
        <label for="">Topic Description</label>
        <vue-editor v-model="localTopic.content" :editorToolbar="customToolbar" :placeholder="'What is this topic about?'"></vue-editor>
      </div>
      <h6>Image &amp; Colors</h6>
      <div class="inputWrap">
        <label for="">Banner Image</label>
        <input type="text" placeholder="full filename" v-model="localTopic.banner"/>
      </div>
      <div class="flex-wrap">
        <div class="inputWrap flex-half">
          <label for="">Primary Color</label>
          <input type="text" placeholder="#000000" v-model="localTopic.primaryColor"/>
        </div>
        <div class="inputWrap flex-half">
          <label for="">Secondary Color</label>
          <input type="text" placeholder="#000000" v-model="localTopic.secondaryColor"/>
        </div>
      </div>
      <h6>Available Tracks</h6>
      <div class="inputWrap">
        <input name="query" type="text" placeholder="Search Available Tracks" v-model="searchQuery">
        <span class="clearSearch" v-if="searchQuery" @click="clearFilter">Show all</span>
      </div>
      <AddTracks v-if="allTracks" @addTrack="addUnitToTrackUnits" :filterKey="searchQuery" :allTracks="allTracks" :topicTracks="localTopic.tracks" />

      <div class="buttonWrap">
        <div class="button" v-bind:data-btnText="slug ? 'Update Topic' : 'Create Topic'" @click="updateTopic()" data-btnTextHover="Save">&nbsp;</div>
      </div>

    </section>

    <div v-if="allTracks" id="preview">
      <h6>Topic Preview</h6>
      <div class="createPreview">
        <div class="verticalSpacer">
            <h2>{{localTopic.title}}</h2>
        </div>

          <div id="trackInfo">
            <div class="trackOverview" v-html="localTopic.content"></div>
          </div>


        <section id="trackList">
          <draggable id="unitList" v-model="localTopic.tracks">
            <transition-group name="sortList">
              <div class="unitWrap sortList-item" v-for="slug in localTopic.tracks" :key="slug">
                <div class="remove" @click="removeTrack(slug)"></div>
                <TrackTile :track="getTrack(slug)" :key="slug" />
              </div>
            </transition-group>
          </draggable>
        </section>
      </div>

    </div>

  </div>
</template>

<script>
import uuid from 'uuid'
import slugify from 'slugify'
import AddTracks from './CreateTopic.AddTracks'
import TrackTile from '@/components/TrackTile'
import AmplifyStore from '@/store/store'
import { mapState } from 'vuex'
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'

export default defineComponent({
  name: 'CreateTopic',
  props: ['slug'],
  components: { AddTracks, TrackTile, draggable: VueDraggableNext },
  data () {
    return {
      searchQuery: '',
      localTopic: false,
      trackTitle: '',
      trackIntro: '',
      trackAudience: '',
      trackIcon: '',
      trackUnits: [],
      trackId: false,
      topicDisabled: false,
      trackCreated: false,
      topicFeatuted: false,
      customToolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link', 'clean']
          ]
    }
  },
  created () {
    if(this.slug && this.topics){
      let existingTopic = this.topics.find( t => t.slug === this.slug )
      if(existingTopic && !existingTopic.tracks) existingTopic.tracks = []
      this.localTopic = Object.assign({}, existingTopic )
    }
    if(!this.slug) this.localTopic = {title:"",content:"",disabled:false,featured:false,slug:"",tracks:[]}
    if(!this.allTracks) AmplifyStore.dispatch('private/getAllTracks')
  },
  unmounted () {
    this.localTopic = false
  },
  methods: {
    clearFilter: function(){
      this.searchQuery = ''
    },
    addUnitToTrackUnits: function(track){
      if(this.localTopic.tracks){
        let tracks = this.localTopic.tracks.map( t => t)
        tracks.push(track.slug)
        this.localTopic.tracks = tracks
      }
      if(!this.localTopic.tracks){
        this.localTopic = Object.assign(this.localTopic, { tracks: [`${track.slug}`] } )
      }
    },
    removeTrack: function(slug) {
      let index = this.localTopic.tracks.indexOf(slug)
      let tracks = this.localTopic.tracks.map( t => t);
      tracks.splice(index, 1);
      this.localTopic.tracks = tracks
    },
    toggleTrackDisplay: function() {
      this.localTopic.disabled = !this.localTopic.disabled
    },
    getTrack: function(slug) {
      let track = this.allTracks.find( t => t.slug === slug)
      if(track) return track
      return false
    },
    titleDuplicated: function() {
      if(this.topics){
        let matchingTitle = this.topics.filter(t => {
          return t.title.toLowerCase() === this.localTopic.title.toLowerCase().trim()
        })[0]

        if(matchingTitle) return true
      }
      return false
    },
    updateTopic: async function() {
      let that = this

      // Check for duplicated title
      if(!this.slug && this.titleDuplicated()){
        AmplifyStore.commit('set_flashMessage', {
           show: true,
           level: 'error',
           header: 'Unable to create topic!',
           message: 'Topic title is duplicated: ' + that.localTopic.title
         })
        return
      }

      // If not existing topic push to array
      if(!this.slug){
        this.localTopic.slug = slugify(this.localTopic.title.toLowerCase())
        this.topics.push(this.localTopic)
      }

      // If exist in topic overwrite object
      if(this.slug){
        let topicIndex = this.topics.map( t => t.slug ).indexOf(this.localTopic.slug)
        if(topicIndex < 0) return
        Object.assign(this.topics[topicIndex], this.localTopic)
      }

      let params = {
          TableName: `${this.ENV.tablePrefix}Settings`,
          Key: { 'config': this.currentSettings.config },
          UpdateExpression: 'set topics = :tVal',
          ExpressionAttributeValues: {
            ":tVal": this.topics
           },
          ReturnValues: "ALL_NEW"
      }

      await this.docClient.update(params, function(err, data) {
        if (err) {
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'warn',
            header: 'Create Failed',
            message: 'Topic: ' + that.localTopic.title + '. Please try again.'
          })
        } else {
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Saved!',
            message: 'Topic: ' + that.localTopic.title
          })
          // console.log(data)
          AmplifyStore.commit('private/setTopics', data.Attributes.topics)
        }
      });

    }
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    topics() { return AmplifyStore.state.private.topics },
    allTracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    currentSettings() { return AmplifyStore.state.admin.settings },
    // topicTracks() {
    //   console.log(this.localTopic)
    //   if(this.allTracks && this.localTopic){
    //     let topicTracks = this.localTopic.tracks.map( s => {
    //       return this.allTracks.find( t => t.slug === s)
    //     });
    //     return topicTracks.filter( t => !t.topicDisabled )
    //   }
    //   return []
    // }
  }
})
</script>

<style scoped>

  .error{
    border: 1px solid red;
    background-color: red
  }

</style>
