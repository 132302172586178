
<template>
    <div class="tableWrap">
      <table id="availableUnits">
        <thead>
          <tr>
            <th @click="sortBy('title')" :class="{ active: sortKey == 'title' }">
              <span class="arrow" :class="sortOrders['title'] > 0 ? 'asc' : 'dsc'">Title</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="track in filteredData" v-bind:key="track.trackId">
            <td class="title" @click="addUnit(track)">{{track.title}}</td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'AddTracks',
  props: ['allTracks', 'topicTracks', 'filterKey'],
  data () {
    return {
      sortKey: 'title',
      sortOrders: {title: 1, type: 1, createdAt: 1}
    }
  },
  mounted() {

  },
  methods: {
    prettyTime: function(timeStamp) {
      return dayjs(timeStamp).format('MM/D/YY');
    },
    addUnit: function(track) {
      this.$emit('addTrack', track)
    },
    sortBy: function (key) {
      this.sortKey = key
      this.sortOrders[key] = this.sortOrders[key] * -1
      const params = {
        sortKey: key,
        order: this.sortOrders[key]
      }
    }
  },
  computed: {
    filteredData: function () {
      var sortKey = this.sortKey
      var filterKeys = this.filterKeys
      var filterKey = this.filterKey && this.filterKey.toLowerCase()
          filterKey = filterKey.trim()
      var order = this.sortOrders[sortKey] || 1
      var data = this.availableTracks

      if (filterKey) {
        data = data.filter(function (row) {

          return Object.keys(row).some(function (key) {
            if(filterKeys.indexOf(key) > - 1){
              return String(row[key]).toLowerCase().indexOf(filterKey) > -1
            }
          })
        })
      }
      if (sortKey) {

        data = data.slice().sort(function (a, b) {
          a = String(a[sortKey])
          b = String(b[sortKey])
          return (a === b ? 0 : a > b ? -1 : 1) * order
        })
      }
      return data
    },
    filterKeys: function(){
      return Object.keys(this.sortOrders)
    },
    availableTracks: function() {
      if(this.allTracks){
        // let trackIds = this.allTracks.map( t => t.trackId)

        let notInUnit = this.allTracks.filter(u =>{
          return !this.topicTracks.includes(u.slug)
        });

        let notDisabled = notInUnit.filter( n => !n.trackDisabled)

        return notDisabled
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
