
<template>
  <div>
    <img :src="url" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Storage, Logger } from 'aws-amplify';

export default {
  name: 'SSSImage',
  props: ['imagePath'],
  data () {
    return {
      url: null,
      error: '',
      logger: {},
    }
  },
  mounted() {
    this.logger = new Logger(this.$options.name);
    this.getImage();
  },
  methods: {
    getImage() {
      if (!this.imagePath) {
        return this.setError('Image path not provided.')
      }
      Storage
        .get(this.imagePath)
        .then((url) => {
          this.url = url
          })
        .catch(e => this.setError(e));
    },
    setError: function(e) {
      this.error = e.message || e;
      this.logger.error(this.error);
    }
  }
}
</script>

<style scoped>
  .amplify-image {
    width: 30%;
    margin: 0.2em;
    border-radius: 6px;
    border: 2px solid var(--color-white);
    cursor: pointer;
  }
</style>
