
<template>
  <div class="flash" v-bind:class="[flash.show ? 'open': '', flash.level]">
    <div class="header">
      {{flash.header}} &nbsp; 
    </div>
    <p>{{flash.message}} &nbsp; </p>
    <div class="closeBtn" @click="hideFlash()"></div>
  </div>
</template>

<script>
import AmplifyStore from '@/store/store'

export default {
  name: 'Flash',
  data () {
    return {
    }
  },
  mounted() {
    
  },
  methods: {
    hideFlash: function() {
      AmplifyStore.commit('set_flashMessage', {
        show: false,
        header: '',
        message: '',
        level: 'warn'
      })
    },
    timeOutFlash: function(sec) {
      let that = this;
      clearTimeout(timer);
      var timer = setTimeout(function(){
        that.hideFlash();
      },sec);
    }
  },
  watch: {
    flash(val) {
      if(val.show == true){
        this.timeOutFlash(10000)
      }
    }
  },
  computed: {
    flash() { return AmplifyStore.state.flash }
  }
}
</script>

<style scoped>
  
</style>
