<template>
    <div id="auth" class="bodySpacer">
    <section id="top">
        <img class="stat" src="/images/stat.svg">
        <!-- <div id="authInputs" class="sso mainAuth" v-if="envName !== 'Development' && ssoType === 'medica'">
                <h2>Sign in with Medica</h2>
                <p>We need to redirect you to Medica's authorization screen. Once complete you'll be brought back to MSU.</p>
                <div class="button" data-btnText="Medica Sign In" data-btnTextHover="Medica Sign In" @click.prevent="doRedirect(oAuthDestMedica)">&nbsp;</div>
        </div> -->
        <div id="authInputs" class="sso mainAuth" v-if="envName !== 'Development' && ssoType === 'fiduciary'">
                <h2>Fiduciary Counselling</h2>
                <p>We need to redirect you to login through your organization. Once complete you'll be brought back to MSU.</p>
                <div class="button" data-btnText="FCI Sign In" data-btnTextHover="FCI Sign In" @click.prevent="doRedirect(oAuthDestSalesForce)">&nbsp;</div>

        </div>
        <div id="authInputs" class="sso dev mainAuth" v-if="envName === 'Development'">
                <h2>3rd Party Sign-in</h2>
                <div class="actions">
                  <div class="button" data-btnText="Medica" data-btnTextHover="Medica" @click.prevent="doRedirect(oAuthDestMedica)">&nbsp;</div>
                  <div class="button" data-btnText="Fiduciary" data-btnTextHover="Fiduciary" @click.prevent="doRedirect(oAuthDestSalesForce)">&nbsp;</div>
                  <div class="button" data-btnText="Auth0" data-btnTextHover="Auth0" @click.prevent="doRedirect(oAuthDestAuth0)">&nbsp;</div>
                </div>
        </div>
    </section>
    <welcome signInType="sso" />
  </div>
</template>

<script>
import ENV from '@/config';
import welcome from './Welcome';

export default {
    name: 'SSOAuth',
    components: {welcome},
    props: ['via', 'destination','ssoType'],
    data () {
      return {
        envName: ENV.name,
        oAuthDestMedica: ENV.oAuthBeforeSignInMedica,
        oAuthDestFiduciary: ENV.oAuthBeforeSignInFiduciary,
        oAuthDestAuth0: ENV.oAuthBeforeSignInAuth0,
        oAuthDestSalesForce: ENV.oAuthBeforeSignInSalesForce
      }
    },
    computed: {
      
    },
    mounted() {
      // console.log('PARAMS', this.via, this.destination)
      if(this.via && this.destination){
        this.oAuthDestMedica = `${this.oAuthDestMedica}&state=${this.via}/${this.destination}`
        this.oAuthDestFiduciary = `${this.oAuthDestFiduciary}&state=${this.via}/${this.destination}`
        return
      }
      if(this.via){
        this.oAuthDestMedica = `${this.oAuthDestMedica}&state=${this.via}`
        this.oAuthDestFiduciary = `${this.oAuthDestFiduciary}&state=${this.via}`
        return
      }
    },
    methods: {
      doRedirect: function(version){
          window.location.href = version
      }
    }
}
</script>

<style scoped>

</style>