<template>
  <div id="firstTime" class="bodySpacer">
    <section class="top">
        <div class="left">
            <div class="circle"><img src="/images/hand-wave.svg"></div>
        </div>
        <div class="right">
            <h2>Welcome to Money Sanity U</h2>
            <p>Let's get your account created. Start by using the temporary login information you received in an email from contact@sharesavespend.com.</p>
            <p class="helpText">Hint: If you can’t find your log-in email, check your Spam or Junk folder.</p>
        </div>
    </section>
    <section class="firstTimeContent">
        <!-- <img class="stat" src="/images/stat.svg"> -->
        <div class="helperImage">
            <img src="/images/emailHelper.png">
        </div>
        <div id="authInputs">
          <form>
            <h3>Your Email &amp; Temporary Password</h3>
            <input type="email" name="username" id="loginUser" v-model="userEmail" placeholder="Email Address" />
            <input type="password" name="password" id="loginPass" v-model="userPassword" placeholder="Temporary Password" />
            <div class="loginFooter">
              <div class="button" data-btntext="Sign In" data-btntexthover="Sign In" @click="doLogin">&nbsp;</div>
            </div>
          </form>
          <div class="error" v-if="amplifyError">{{ amplifyError }} - If you received an invitation more than 90 days ago, please contact us for help.</div>
        </div>
    </section>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import router from '../router'
import AmplifyStore from '../store/store'

export default {
  name: 'FirstSignIn',
  // props: ['authConfig'],
  data () {
    return {
        amplifyError: '',
        logger: {},
        verNum: null,
        ses: null,
        userEmail: null,
        userPassword: null,
    }
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    GetUser(amplify) {
      return amplify.Auth.currentAuthenticatedUser().then((user) => {
        if (!user) {
          return null;
        }
        return user;
      }).catch(e => new Error(e));
    },
    async doLogin() {
      if(this.userEmail && this.userPassword) {
        try {
          const user = await Auth.signIn(this.userEmail, this.userPassword);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            AmplifyStore.commit('setUser', user);
            router.push({ path: '/auth/newpassword' })
          }
        } catch (error) {
          console.log(error)
          this.amplifyError = error.message
        }
      }
    },
  }
}

</script>