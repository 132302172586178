<template>
    <div id="notFound">
        <div class="bodySpacer">
            <div class="piggy">

            </div>
            <h1>Page not found <span>(Error 404)</span></h1>
            <h2>Oops, that page isn't available for withdrawl (probably a bank error).<br />Why not check the couch cushions at <a href="/">Home</a>?</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>

<style scoped>

</style>