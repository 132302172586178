<template>

    <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="adminBlock">
        <div class="groupList" v-if="this.user.attributes['custom:role'] === 'admin'">
            <div class="adminHeader">
              <h2>Organizations:</h2>
              <button class="add" @click="displayModal()">Create Organization</button>
            </div>
            <table class="linkTable groupList" v-if="allGroups">
              <thead>
                <tr>
                  <th>Active</th>
                  <th>Members</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in activeGroups" v-bind:key="g.slug" @click="$router.push({ name: 'AdminGroup', params: {slug: g.slug} })" v-bind:class="g.groupStatus">
                  <td class="name">{{g.title}}</td>
                  <!-- <td class="manager">{{g.manager[0]}} <span v-if="g.manager.length >= 2">(+{{g.manager.length - 1}} more)</span></td> -->
                  <td class="members">{{g.members.length}} / {{g.maxMembers > 0 ? g.maxMembers :'unlimited'}}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Inactive</th>
                  <th>Members</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="g in inactiveGroups" v-bind:key="g.slug" @click="$router.push({ name: 'AdminGroup', params: {slug: g.slug} })" v-bind:class="g.groupStatus">
                  <td class="name">{{g.title}}</td>
                  <td class="members">{{g.members.length}} / {{g.maxMembers > 0 ? g.maxMembers :'unlimited'}}</td>
                </tr>
              </tbody>
            </table>
        </div>
        <GroupDetail v-if="this.user.attributes['custom:role'] === 'manager'" :slug="this.user.attributes['custom:groupSlug']" />
      </div>

      <div id="modal" v-if="showModal == true">
        <div id="modalContent">
          <div class="closeBtn" @click="hideModal()"></div>
            <h2>Create New Organization</h2>
            <div class="newGroup">
                <div class="inputWrap">
                  <label>Organization Name</label>
                  <input type="text" v-model="createGroupName" placeholder="Used in MSU Admin - Cannot be changed!"/>
                </div>
                <div class="inputWrap">
                  <label>Manager First</label>
                  <input type="text" v-model="createUserFirst" placeholder="Optional"/>
                </div>
                <div class="inputWrap">
                  <label>Manager Last</label>
                  <input type="text" v-model="createUserLast" placeholder="Optional"/>
                </div>
                <div class="inputWrap">
                  <label>Manager Email Address <span class="specialInfo">use <em>superadmin</em> if no manager</span></label>
                  <input type="text" v-model="createGroupManager" placeholder="name@email.com"/>
                </div>
                <div class="inputWrap">
                  <label>Max Members</label>
                  <input type="number" v-model="createGroupMax"/>
                  <span>
                    (0 is unlimited)
                  </span>
                </div>
                <div class="buttonWrap">
                  <button @click="createGroup()">Create Group</button>
                </div>
            </div>
        </div>
      </div>
    </div>

</template>

<script>
import AmplifyStore from '@/store/store'
import GroupDetail from '@/components/admin/components/Group'
import { API } from 'aws-amplify'
import AdminNav from '@/components/admin/components/AdminNav'

export default {
  name: 'AdminMain',
  components: {GroupDetail, AdminNav},
  data () {
    return {
      createGroupName: '',
      createGroupMax: 0,
      createUserEmail: '',
      createUserFirst:'',
      createUserLast: '',
      createGroupManager: '',
      createAccountType: null,
      showModal: false,
    }
  },
  created () {
    // Initialize admin
    if(this.user.attributes["custom:role"] === "admin"){
      // AmplifyStore.dispatch('admin/listUsersInCognitoGroup')
      AmplifyStore.dispatch('admin/getAllGroups')
    }

    // Initialize manager
    if(this.user.attributes["custom:role"] === "manager"){
      AmplifyStore.dispatch('admin/getGroupBySlug', this.user.attributes["custom:groupSlug"])
    }
  },
  unmounted () {
    AmplifyStore.commit('admin/set_allGroups', false)
  },
  methods: {
    disableUser: function(username) {
      AmplifyStore.dispatch('admin/disableUser', { username: username })
    },
    enableUser: function(username) {
      AmplifyStore.dispatch('admin/enableUser', { username: username })
    },
    deleteUser: function(username) {
      AmplifyStore.dispatch('admin/deleteUser', { username: username })
    },
    getUserList: function(manager) {
      AmplifyStore.dispatch('admin/getUserList', manager)
    },
    // createUser: function() {
    //   let params = {
    //     username: this.createUserEmail,
    //     role: this.createAccountType
    //   }

    //   AmplifyStore.dispatch('admin/createUser', params)
    // },
    displayModal: function(){
      this.showModal = true
    },
    hideModal: function(){
      this.showModal = false
    },
    adminGetUser: async function(email) {
      let params = {
        UserPoolId: this.user.pool.userPoolId,
        Username: email
      }
      return await this.cognito.adminGetUser(params).promise();
    },
    lambdaCampaignMonitor: async function(params, action){
      let apiName = 'MSU_ADD_TO_CM';
      let path = '/';
      let myInit = {
          headers: {},
          response: true,
          body: {
              user: params,
              type: action
          }
      }
      return await API.post(apiName, path, myInit)
      .then(response => {
          console.log(action + "User " + params.email + " to/from CM")
          return
      });
    },
    createGroup: function() {
      this.createGroupManager = this.createGroupManager.toLowerCase()

      if(!this.createGroupName){
        AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'error',
            header: 'Error creating organization',
            message: "Name Required"
          });
        return
      }
      if(!this.createGroupManager){
        AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'error',
            header: 'Error creating organization',
            message: "Manager Email Address is Required"
          });
        return
      }


      let groupParams = {
        "Item": {
          title: this.createGroupName,
          maxMembers: this.createGroupMax,
          groupStatus: 'active',
          manager: [this.createGroupManager],
          members: []
        }
      }

      let userParams = {
        "username": this.createGroupManager,
        "email": this.createGroupManager,
        "role": 'manager',
        "group": this.createGroupName,
        "first": this.createUserFirst,
        "last": this.createUserLast,
      }
      
      if(this.createUserFirst) userParams.first = this.createUserFirst
      if(this.createUserLast) userParams.last = this.createUserLast

      // added to allow Super Admin to manage groups
      if(this.createGroupManager != "superadmin"){
      this.adminGetUser(this.createGroupManager)
        .then(data => {
          AmplifyStore.commit('set_flashMessage', {
              show: true,
              level: 'error',
              header: 'Error creating organization',
              message: "Users with email already exists"
            });
          return
        })
        .catch( err => {
          console.log(err)
        })
      }

      AmplifyStore.dispatch('admin/createGroup', groupParams)
        .then(() => {
          this.hideModal()
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'New organization created',
            message: groupParams["Item"].title,
          });
          // added to allow Super Admin to manage groups
          if(userParams.username != "superadmin"){
            this.lambdaCampaignMonitor(userParams, 'subscribe')
            AmplifyStore.dispatch('admin/createUser', userParams)
          }
          AmplifyStore.dispatch('admin/getAllGroups')
        })
    },
  },
  computed: {
    user() { return AmplifyStore.state.user },
    cognito() { return AmplifyStore.state.cognito },
    allGroups() { return AmplifyStore.state.admin.allGroups },
    sortedGroups () {
      let copy = Array.from(this.allGroups).slice()
      return copy.sort(function(a, b) {
        var textA = a.title.toLowerCase();
        var textB = b.title.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    activeGroups () {
      return this.sortedGroups.filter( g => { return g.groupStatus === 'active' })
    },
    inactiveGroups () {
      return this.sortedGroups.filter( g => { return g.groupStatus === 'disabled' })
    }

    
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }

  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
