const store = {
    namespaced: true,
    state: {
        allTracks: null,
        trackDisplay: null,
        topics: null,
        userProgress: null,
        userList: false,
        group: false,
    },
    actions: {
        getAllTracks: function({ rootState, commit }) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}Tracks`
            }

            rootState.docClient.scan(params, function(err, data) {
                if (err) commit('setAllTracks', null)
                else commit('setAllTracks', data.Items)
            })

        },
        getUserProgress: function({ rootState, commit }) {
            let params = {
                TableName: `${rootState.ENV.tablePrefix}Users`,
                Key: { "user": rootState.user.attributes.sub }
            }

            rootState.docClient.get(params, function(err, data) {
                if (err) commit('setUserProgress', null)
                else commit('setUserProgress', data.Item)
            })

        },
        getUserGroup: async function({ rootState, commit }) {
            if (!rootState.user.attributes["custom:groupSlug"]) return

            let params = {
                TableName: `${rootState.ENV.tablePrefix}UserGroups`,
                Key: { "slug": rootState.user.attributes["custom:groupSlug"] },
                ProjectionExpression: "title, groupImage, slug, settings, subGroups, groupDisplayName, groupLinkTo"
            }
            let getGroup = rootState.docClient.get(params).promise()

            await getGroup
                .then(data => commit('setUserGroup', data.Item))
                .catch(err => commit('setUserGroup', null))
        }
    },
    mutations: {
        setTrackDisplay(state, display) {
            state.trackDisplay = display
        },
        setTopics(state, topics) {
            state.topics = topics
        },
        setAllTracks(state, tracks) {
            state.allTracks = tracks
        },
        setUserProgress(state, progress) {
            state.userProgress = progress
        },
        setUserGroup(state, group) {
            state.group = group
        },
    }
}

export default store