<template>
  <div>
    <div class="headerBorder"></div>
    <div id="introSection" class="fullWidth">
      <section id="topicOverview" class="bodySpacer verticalSpacer">
        <div v-if="!userTracks.length">
          <h3>Looks like you don't have any saved tracks</h3>
        </div>
        <div v-if="userTracks.length">
          <h3>My Progress</h3>
          <div>
            <p>Pick up right where you left off! Here's a list of your recently accessed tracks.</p>
          </div>
        </div>
      </section>
    </div>



    <div id="allTracks" class="bodySpacer verticalSpacer">
        <section id="trackList" v-if="userTracks && userTracks.length" v-bind:class="this.displayGrid ? 'grid' : 'list'">
          <div class="trackListHeader">({{userTracks.length}}) Recent &amp; Active Tracks</div>
          <TrackTile v-for="track in userTracks" :track="track" :key="track.slug" />
        </section>
    </div>

  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import TrackTile from '@/components/TrackTile'

export default {
  name: 'userProgress',
  components: { TrackTile },
  data () {
    return {
      displayGrid: true,
    }
  },
  mounted () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    if(!this.userProgress) AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)
    
    if (this.user && this.user.attributes['custom:role']!='admin'){
      // this.$plausible.trackPageview()
    }
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    }
  },
  computed: {
    user() { return AmplifyStore.state.user },
    tracks() { return AmplifyStore.state.private.allTracks },
    userTracks() {
      if(this.tracks && this.userProgress){
        let tracks = this.tracks.filter( t => this.userProgress.activeTracks.includes(t.slug));
        tracks.unshift(tracks.splice(tracks.findIndex( tr => tr.slug === this.userProgress.lastTrack), 1)[0])
        return tracks
      }
      return []
    },
    userProgress() { return AmplifyStore.state.private.userProgress }
  }
}
</script>

<style scoped>
</style>
