<template>
  <div class="track" v-bind:class="[setTrackStatusClass(track.slug), isUpdated(track)]" v-bind:key="track.trackId" :style="cssVars">
    <div class="trackWrapper">
      <div class="progress">
          <span class="percent" v-if="(completePercent(track.slug) < 100)">{{completePercent(track.slug)}}% Complete</span>
          <span class="percent" v-if="(completePercent(track.slug) == 100)">Track Complete</span>
        </div>
        <div class="info">
          <div class="svgIcon" v-html="findTrackIcon"></div>
        <div class="text">
          <h2>{{track.title}}</h2>
          <h5>
            <span v-if="track.units">{{track.units.length}} Lessons</span>
            <!-- <span>About {{estimateTime(track.units.length)}} Minutes</span> -->
          </h5>
        </div>
      </div>
      <div class="action">
        <div @click="getTrack(track.slug)" class="viewTrack button" v-bind:class="track.slug === track.slug ? 'active' : '' " :data-btnText="setButtonName(track.slug)" :data-btnTextHover="setButtonName(track.slug)">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import AmplifyStore from '../store/store'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default defineComponent({
  name: 'TrackTile',
  components: {  },
  props: ['track'],
  data () {
    return {
      lastTrack: false,
      displayGrid: true,
    }
  },
  mounted () {
    if(!this.allIcons) AmplifyStore.dispatch('admin/getAllIcons')
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    },
    getTrack(slug){
      this.$router.push(`/track/${slug}`)
    },
    getTopic(slug){
      this.$router.push(`/topic/${slug}`)
    },
    lastActive(){
      if(this.userProgress && this.userProgress.lastTrack && this.tracks){
        this.lastTrack = this.tracks.find((t) => {
          return t.slug === this.userProgress.lastTrack
        })
        return true
      }

      return false
    },
    isUpdated(track){
      let cssClass = ''
      let today = dayjs()
      
      if(today.diff(track.createdAt, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
        cssClass = 'newContent'
      }
      else if(track.updateContentDate && today.diff(track.updateContentDate, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
        cssClass = 'updatedContent'
      }

      // console.log(track.title)
      // console.log('Update content date (track): ' + track.updateContentDate)
      // console.log('new flag (unit) ' + this.ENV.newFlagUnit)

      return cssClass
    },
    // isActive(slug){
    //   if(this.userProgress){
    //     let userIsActive = this.userProgress.activeTracks.find(t => {
    //       return t === slug
    //     })
    //
    //     if(userIsActive) return 'active'
    //   }
    //
    //   return ''
    // },
    completePercent(slug){
      if(this.userProgress) {
        let track = this.tracks.find(t => {
          return t.slug === slug
        })

        let completedUnits = track.units.filter(u => {
          if(this.userProgress.activeUnits.includes(u)) return u
        })

        let completePercent = Math.floor((completedUnits.length / track.units.length) * 100)
        if(completePercent) return completePercent
      }
      return ''
    },
    setTrackStatusClass(slug){
      let percent = this.completePercent(slug)
      if (percent === 100) return 'trackComplete'
      if (percent > 0) return 'inProgress'
      return ''
    },
    setButtonName(slug){
      let percent = this.completePercent(slug)
      if (percent === 100) return 'View Again'
      if (percent > 1) return 'Continue'
      return 'Get Started'
    },
    estimateTime(numTracks){
      var t = Math.round(numTracks * 4.25)
      return t
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    tracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    topics() { return AmplifyStore.state.private.topics },
    allIcons() { return AmplifyStore.state.admin.allIcons },
    userProgress() { return AmplifyStore.state.private.userProgress },
    enabledTracks() {
      if(this.tracks && this.trackDisplay){
        let orderedTracks = this.trackDisplay.map( s => {
          return this.tracks.find( t => t.slug === s)
        });
        return orderedTracks.filter( t => !t.trackDisabled )
      }
    },
    parentTopic(){
      if(this.topics){
        let theParent = null
        this.topics.forEach(thisTopic => {
          if(!theParent){
            let match = thisTopic.tracks.find(tr => tr === this.track.slug)
            if (match) {
              theParent = thisTopic
            }
          }
        });
        return theParent;
      }
    },
    cssVars() {
      if(this.parentTopic){
        return {
          '--track-primary': this.parentTopic.primaryColor,
          '--track-secondary': this.parentTopic.secondaryColor
        }
      } else {
        return
      }
    },
    findTrackIcon(){
      let trackIcon = false
      if(this.allIcons) trackIcon = this.allIcons.find((i) => i.slug === this.track.iconSlug)

      if(trackIcon) return trackIcon.svgfile
      else return '' 
    },  
  }
})
</script>
