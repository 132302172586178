<template>
  <div id="setupAccount" class="bodySpacer">
    <section class="top">
        <div class="left">
          <div class="circle"><img src="/images/locked-user.svg"></div>
        </div>
        <div class="right">
          <h2>Set New Password</h2>
          <p>We've sent you an email containing a "Verification Code" (remember to check your spam folder).</p>
        </div>
    </section>
    <section>
        <div class="newPassInner">
          <div class="inputWrap" v-if="!this.user">
            <label for="">Your Email Address</label>
            <input v-model="username" type="text" placeholder="name@email.com"  />
          </div>
          <div class="inputWrap">
            <label for="">Verification Code</label>
            <input v-model="verification" type="text" placeholder="XXXXXX" autocomplete="off" />
          </div>
          <div class="inputWrap">
            <label for="">Choose a new password</label>
            <input v-model="password" type="password" placeholder="Password" autocomplete="off" @keyDown="validatePassword" />
          </div>
            <strong>Must contain at least:</strong>
            <ul class="passwordHelp">
              <li v-bind:class="{valid: hasLength}">At least 8 characters long</li>
              <li v-bind:class="{valid: hasUpper}">1 capital or more Letters</li>
              <li v-bind:class="{valid: hasNumber}">1 or more numbers</li>
              <li v-bind:class="{valid: hasSpecial}">1 or more special characters (!@#$%)</li>
            </ul>
        </div>
    </section>

    <div class="buttonWrap">
        <div class="button" data-btnText="Reset Password" data-btnTextHover="Reset Password" :class="!hasUpper || !hasSpecial || !hasNumber || !hasLength ? 'disabled':''" @click="setNewPassword()">&nbsp;</div>
      </div>

  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import router from '../router'
import { Auth } from 'aws-amplify'

export default {
  name: 'ResetPassword',
  data () {
    return {
      username: '',
      verification: '',
      password: '',
      hasUpper: false,
      hasSpecial: false,
      hasNumber: false,
      hasLength: false,
      errorMessage: '',
    }
  },
  created () {

  },
  methods: {
    validatePassword: function() {
      this.hasUpper = false
      this.hasSpecial = false
      this.hasNumber = false
      this.hasLength = false

      const lowerCaseLetters = /[a-z]/g,
            upperCaseLetters = /[A-Z]/g,
            numbers = /[0-9]/g,
            specials = /[!@#$%^&*\[\(\)\]\-_\+\=]/g

      if(this.password.match(upperCaseLetters)) this.hasUpper = true
      if(this.password.match(specials)) this.hasSpecial = true
      if(this.password.match(numbers)) this.hasNumber = true
      if(this.password.length > 7 && this.password.length < 100) this.hasLength = true
    },
    async setNewPassword(){
      let theUser = this.username
      if (this.user) theUser = this.user.username
      try {
        const loggedInUser = await Auth.forgotPasswordSubmit(
          theUser,
          this.verification, // the Cognito User Object
          this.password, // the new password
        )
        console.log(loggedInUser)
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'success',
          header: 'Your password has been updated!',
          message: 'Login to continue.'
        })
        router.push({path: '/dashboard'})
      }
      catch(error) {
        console.log('New Password Error ' + error)
      }
    }

  },
  computed: {
    user() { return AmplifyStore.state.user },
  },
  watch: {
    'password'() {
      this.validatePassword()
    }
  }
}
</script>

<style scoped>
</style>
