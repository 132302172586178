<template>

  <div class="admin bodySpacer verticalSpacer">
    <AdminNav />
    <div class="layoutPresets" v-if="user.attributes['custom:role'] === 'admin'">
      <h3>Layout Presets ({{allSettings.length}})</h3>
      <select v-model="selected" v-on:change="updateCurrentSettings">
        <option v-for="s in allSettings" :value="s.config">{{s.config}}</option>
      </select>
      <div class="spacer"></div>
      <button class="small" @click="toggleModal()">New Layout Preset</button>
    </div>
    <div class="adminBlock display-flex">
        <div class="leftSide">
          <h2>Welcome Track </h2>
          <select name="welcomeTrack" id="welcomeTrack" v-model="welcome" v-on:change="updateSave">
            <option value="none">None</option>
            <option v-for="t in allTracksAlpha" v-bind:key="t.slug" :value="t.slug">{{t.title}}</option>
          </select>
        </div>
        <div class="rightSide">
          <p class="info marginLeft">Choose if a specific track is shown as a "welcome" or "start here" experience.</p>
        </div>
    </div>

    <div class="adminBlock display-flex">
          <div class="leftSide">
            <h2>Topic Order ({{topicOrder.length}}) </h2>
            <draggable class="trackGrid topicGrid" v-model="topicOrder">
                <transition-group>
                  <div v-for="topic in topicOrder" class="sortTrack" v-bind:key="topic.slug" v-bind:class="topic.disabled ? 'disabled' : ''">
                    <div class="icon" >
                      <span v-if="topic.disabled">Disabled</span>
                    </div>
                    <h4>{{topic.title}}</h4>
                  </div>
                </transition-group>
            </draggable>
          </div>
          <div class="rightSide">
            <p class="info marginLeft">Topics are groupings of tracks. Topics order and content are unique to EACH layout preset!</p>
          </div>
    </div>

    <div class="adminBlock display-flex">
      <div class="leftSide">
        <h2>Track Order ({{sortedTracks.length}})</h2>
        <div v-if="!sortOrder.length">
          Uh-oh looks like something went wrong here...
        </div>
        <draggable class="trackGrid" v-model="sortOrder">
            <transition-group>
              <div v-for="track in sortedTracks" class="sortTrack" v-bind:key="track.slug" v-bind:class="track.trackDisabled ? 'disabled' : ''">
                <div class="icon" v-bind:class="track.icon">
                  <span v-if="track.trackDisabled">Disabled</span>
                </div>
                <h4>{{track.title}}</h4>
                <button @click="removeTrack(track.slug)">Remove Track</button>
              </div>
            </transition-group>
        </draggable>
      </div>
      <div class="rightSide availableTracks">
        <div class="feature">
          <label> Feature first track</label>
          <select name="featureTrack" id="featureTrack" v-model="enableFeatured" v-on:change="updateSave">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <p class="info">Tracks in this column won't display until added to the order. The first track in the list may be "featured" for first time users and in the list.</p>
        <h2>Available tracks <span>(not displayed)</span></h2>
        <div v-if="availableTracks && availableTracks.length" class="linkList">
          <div v-for="track in availableTracks" class="content" v-bind:class="track.trackDisabled ? 'disabled' : ''" v-bind:key="track.slug" @click="addTrack(track.slug)">
              {{track.title}}
            <button class="small" >Add</button>
          </div>
        </div>
        <div v-else>
          No more tracks to add
        </div>
      </div>
    </div>

    <div class="buttonWrap">
      <!-- <div v-if="!compareArrays(trackDisplay, sortOrder)" class="button" v-bind:data-btnText="'Save Changes'" @click="updateTrackDisplay()" data-btnTextHover="Save Changes">&nbsp;</div> -->
      <div v-if="checkForChanges()" class="button" v-bind:data-btnText="'Save Changes'" @click="updateTrackDisplay()" data-btnTextHover="Save Changes">&nbsp;</div>
    </div>


    <div id="modal" v-if="showModal === true">
      <div id="modalContent">
        <div class="closeBtn" @click="toggleModal()"></div>
        <CreateSettings @finishedCreating="toggleModal()" />
      </div>
    </div>


  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import CreateSettings from '@/components/admin/components/CreateSettings'
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import AdminNav from '@/components/admin/components/AdminNav'

export default defineComponent({
  name: 'Dashboard.TrackDisplay',
  components: { Draggable: VueDraggableNext, CreateSettings, AdminNav },
  data () {
    return {
      sortOrder: [],
      topicOrder: [],
      selected: false,
      showModal: false,
      enableFeatured: false,
      welcome: false,
      saveChanges: false,
    }
  },
  created () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    this.initTrackDisplay()
    this.selected = this.currentSettings.config
  },
  unmounted () {
    AmplifyStore.commit('private/setAllTracks', false)
  },
  methods: {
    toggleModal: function(){
      this.showModal = !this.showModal
    },
    updateCurrentSettings: function(e){
      let updatedSettings = this.allSettings.find( s => s.config === e.target.value)
      this.selected = e.target.value
      AmplifyStore.commit('admin/setSettings', updatedSettings)
      AmplifyStore.commit('private/setTrackDisplay', updatedSettings.trackDisplay)
      AmplifyStore.commit('private/setTopics', updatedSettings.topics)
      this.initTrackDisplay()
    },
    initTrackDisplay: function(){
      if(this.trackDisplay) this.sortOrder = this.trackDisplay.map(t => t)
      if(this.topics) this.topicOrder = this.topics.map(t => t)
      if(this.currentSettings){
         this.welcome = this.currentSettings.welcomeTrack;
         this.enableFeatured = this.currentSettings.featureTrack;
      }
    },
    compareArrays: function(array1, array2){
      return array1.length === array2.length && array1.every((value, index) => value === array2[index])
    },
    checkForChanges: function(){
      if(!this.compareArrays(this.trackDisplay, this.sortOrder)) { return true;}
      if(!this.compareArrays(this.topics, this.topicOrder)) { return true;}
      if(this.saveChanges) {return true;}
      return false
    },
    updateTrackDisplay: function(){
      let that = this
      let params = {
        TableName: `${this.ENV.tablePrefix}Settings`,
        Key: { "config": this.currentSettings.config },
        UpdateExpression: 'set trackDisplay = :sortOrder, topics = :topicOrder, welcomeTrack = :welcomeTrack, featureTrack = :featureTrack',
        ExpressionAttributeValues: { ":sortOrder": this.sortOrder, ":topicOrder": this.topicOrder, ":welcomeTrack": this.welcome , ":featureTrack": this.enableFeatured},
        ReturnValues: "ALL_NEW"
      }
      this.docClient.update(params, function(err, data) {
        if(err) console.log('Error updating track order')
        else {
          AmplifyStore.commit('private/setTrackDisplay', data.Attributes.trackDisplay)
          AmplifyStore.commit('private/setTopics', data.Attributes.topics)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Display Updated!',
            message: 'Track order & display saved'
          })
        }
      })
    },
    addTrack: function(slug){
      this.sortOrder.push(slug)
    },
    removeTrack: function(slug){
      this.sortOrder = this.sortOrder.filter(value => {
        return value != slug;
      });
    },
    updateSave: function(){
      this.saveChanges = true;
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    allTracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    topics() { return AmplifyStore.state.private.topics },
    allSettings() { return AmplifyStore.state.admin.allSettings },
    currentSettings() { return AmplifyStore.state.admin.settings },
    user(){ return AmplifyStore.state.user },
    sortedTracks(){
      if(this.allTracks && this.sortOrder){
        return this.sortOrder.map( s => {
          return this.allTracks.find( t => t.slug === s)
        });
      }

      // if(this.allTracks){
      //   return this.allTracks.sort((a,b) =>{
      //     return moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
      //   });
      // }
      return []
    },
    availableTracks(){
      if(this.allTracks && this.trackDisplay){
        let that = this;
        return this.allTracks.filter( t => {
          if(!t.trackDisabled){
            return !that.sortOrder.includes(t.slug)
          }
        })
      }
      return this.allTracks
    },
    allTracksAlpha(){
      if(this.allTracks){
        return this.allTracks.sort(function(a,b) {
          var textA = a.title;
          var textB = b.title;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      }
    }
  }
})
</script>