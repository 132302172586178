<template>
  <div>
      <div class="headerBorder"></div>
      <div id="introSection" class="fullWidth" v-bind:class="userProgress && userProgress.activeTracks.length ? '': 'detailedTopics'">
        <section id="welcome" class="bodySpacer verticalSpacer">
          <div v-if="userProgress && !userProgress.activeTracks.length">
            <h3>Start Your Money Sanity U<sup>&reg;</sup> Journey</h3>
          </div>
          <div v-if="userProgress && userProgress.activeTracks.length">
            <h4>Welcome back!</h4>
            <!-- <h3>Continue Your Journey</h3> -->
          </div>
        </section>
        <section id="featured" class="bodySpacer verticalSpacer" v-if="welcomeTrack && userProgress && welcomeTrack.percentComplete <= 50">
          <div class="content">
            <h4>{{welcomeTrack.title}}</h4>
            <div v-html="welcomeTrack.content"></div>
            <div class="button" data-btnText="Start Here" data-btnTextHover="Start Here" @click="getTrack(welcomeTrack.slug)">&nbsp;</div>
          </div>
        </section>

        <section id="topicList" v-if="topics && topics.length > 0" class="bodySpacer verticalSpacer">
          <!-- <h6 v-if="!this.userProgress" >Wondering where to start? Try one of these topics:</h6>
          <h6 v-else>We think you might like these topics:</h6> -->
          <div class="trackListHeader">Topics</div>


          <div class="allTopics grid" v-if="userProgress && userProgress.activeTracks.length">
              <router-link tag="div" :to="{ name: 'UserProgress', params: {} }"  class="topic featured">
                <h2><span>My Progress</span></h2>
                <div class="description">
                  <p>Jump back in where you left off.</p>
                </div>
                <h5><span>{{userProgress.activeTracks.length}} learning tracks</span></h5>
              </router-link>

              <router-link tag="div" :to="{ name: 'Topic', params: { slug: topic.slug} }" v-for="topic in topics" v-bind:key="topic.slug" class="topic" :style="`--track-primary: ${topic.secondaryColor}`">
                <h2><span>{{topic.title}}</span></h2>
                <!-- <div v-if="userProgress && !userProgress.activeTracks.length" class="description" v-html="topic.content"></div> -->
                <div class="description" v-html="topic.content"></div>
                <h5><span>{{topic.tracks.length}} learning tracks</span></h5>
              </router-link>
          </div>

          <div class="allTopics grid" v-if="userProgress && !userProgress.activeTracks.length">

              <router-link tag="div" :to="{ name: 'Topic', params: { slug: topic.slug} }" v-for="topic in topics" v-bind:key="topic.slug" class="topic" :style="`--track-primary: ${topic.secondaryColor}`">
                <h2><span>{{topic.title}}</span></h2>
                <!-- <div v-if="userProgress && !userProgress.activeTracks.length" class="description" v-html="topic.content"></div> -->
                <div class="description" v-html="topic.content"></div>
                <h5><span>{{topic.tracks.length}} learning tracks</span></h5>
              </router-link>
              
              <router-link tag="div" :to="{ name: 'Topic', params: { slug: topic.slug} }" v-for="topic in featuredTopics" v-bind:key="topic.slug" class="topic featured">
                  <h2><span>{{topic.title}}</span></h2>
                  <div class="description" v-html="topic.content"></div>
                  <h5><span>{{topic.tracks.length}} learning tracks</span></h5>
                </router-link>

              <router-link tag="div" to="/AllTracks" class="topic featured">
                <h2><span>All Learning Tracks</span></h2>
                <div class="description"><p>
                  Don’t fancy these topics? Here’s everything &amp; the kitchen sink.
                </p></div>
                <h5><span v-if="enabledTracks">{{enabledTracks.length}} learning tracks</span></h5>
              </router-link>

          </div>

        </section>
      </div>

      <div id="allTracks" class="bodySpacer verticalSpacer">
        <div v-if="!enabledTracks">no tracks</div>
        <section id="trackList" v-if="enabledTracks && enabledTracks.length" class="grid"
        :class="[settings.featureTrack == 'yes' && !showSearchResults ? 'hasFeatured': '', 
                user?.attributes['custom:groupSlug'] === 'demo' ? 'isDemo' : ''
                ]"
        >
          <div class="trackListHeader">All Learning Tracks ({{enabledTracks.length}})</div>
          <TrackTile v-for="track in enabledTracks" :track="track" :key="track.slug" />
        </section>
      </div>

  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import TrackTile from '@/components/TrackTile'
import { usePlausible } from 'v-plausible/vue'

const { trackEvent, trackPageview } = usePlausible()

export default {
  name: 'TrackList',
  components: { TrackTile },
  data () {
    return {
      lastTrack: false,
      displayGrid: true,
      hasProgress: false
    }
  },
  mounted () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    if(!this.userProgress) AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    },
    getTopic(slug){
      this.$router.push(`/topic/${slug}`)
    },
    // lastActive(){
    //   if(this.userProgress && this.userProgress.lastTrack && this.tracks){
    //     this.lastTrack = this.tracks.find((t) => {
    //       return t.slug === this.userProgress.lastTrack
    //     })
    //     return true
    //   }
    //
    //   return false
    // }
    getTrack(slug){
      this.$router.push(`/track/${slug}`)
    },
    trackEventLocal() {
      if (this.user && this.user.attributes['custom:role']!='admin'){
      trackEvent("pageview", 
      { 
        url: window.location,
        props: {
          group: this.group.slug,
          user: this.user.username
        }
      })}
    },
  },
  computed: {
    user() { return AmplifyStore.state.user },
    tracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    settings() { return AmplifyStore.state.admin.settings },
    userProgress() { return AmplifyStore.state.private.userProgress },
    topics() {
      if(AmplifyStore.state.private.topics){
        const list = AmplifyStore.state.private.topics.filter(topic => !topic.disabled && !topic.featured)
        return list
      }
      return []
    },
    featuredTopics() {
      if(AmplifyStore.state.private.topics){
        const list = AmplifyStore.state.private.topics.filter(topic => !topic.disabled && topic.featured)
        return list
      }
      return []
    },
    userProgress() { return AmplifyStore.state.private.userProgress },
    enabledTracks() {
      if(this.tracks && this.trackDisplay){
        let orderedTracks = this.trackDisplay.map( s => {
          return this.tracks.find( t => t.slug === s)
        });
        return orderedTracks.filter( t => !t.trackDisabled )
      }
    },
    welcomeTrack(){
      if(this.user && this.userProgress && this.tracks && this.settings){
        let selectedWelcome = this.settings.welcomeTrack
        let welcomeObj = this.tracks.find(s => {
          return s.slug === selectedWelcome
        });
        if(selectedWelcome && selectedWelcome != 'none' && selectedWelcome != ''){
          welcomeObj.completedUnits = welcomeObj.units.filter(u => {
            if(this.userProgress.activeUnits.includes(u)) return u
          })
          welcomeObj.percentComplete = Math.floor((welcomeObj.completedUnits.length / welcomeObj.units.length) * 100)
        }
        return welcomeObj
      }
    },
  },
  watch: {
    'group'(val) {
      this.trackEventLocal()
    },
  }
}
</script>

<style scoped>
</style>
