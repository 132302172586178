<template>
  <div class="bodySpacer verticalSpacer admin display-flex">
    <AdminNav />
    <div id="adminHead">
      <div>
        <h2>
          <span>Icons</span>
        </h2>
      </div>
    </div>
    <section id="createLeft">
      <div class="icon-block" v-for="icon in allIcons" :key="icon.slug">
        <h5>{{ icon.iconName }}</h5>
        <div v-html="icon.svgfile" class="icon-wrapper"></div>
      </div>
    </section>

    <div id="preview">
      <h6>Add Icons</h6>
      <div class="inputWrap">
          <label for="unitTitle">Icon Name</label>
          <input type="text" v-model="iconName" placeholder=""/>
        </div>
      <div class="inputWrap">
        <label for="unitTitle">Icon File Data</label>
        <textarea class="iconInput" v-model="iconData"></textarea>
      </div>
      <div class="buttonWrap">
        <div class="button" @click="createSVG()" data-btnText="Add Icon" data-btnTextHover="Save">&nbsp;</div>
      </div>
    </div>

  </div>
</template>

<script>
import slugify from 'slugify'
import AmplifyStore from '@/store/store'
import AdminNav from '@/components/admin/components/AdminNav'

export default {
  name: 'IconList',
  components: {
    AdminNav
  },
  data () {
    return {
      iconName: '',
      iconData: '',
    }
  },
  mounted () {
    if(!this.allIcons) AmplifyStore.dispatch('admin/getAllIcons')
  },
  methods: {
    createSVG: function(){
      let that = this;
      let theSlug = slugify(this.iconName.toLowerCase())
      let params = {
        TableName : `${this.ENV.tablePrefix}Icons`,
        Item: {
          "iconName": this.iconName,
          "slug": theSlug,
          "svgfile": this.iconData,
        }
      }
      if(this.allIcons.find(icn => icn.slug === theSlug)) {
        console.log("oops duplicate icon name")
      } else {
        this.docClient.put(params, function(err, data) {
          if(err) {
            console.log(err)
            AmplifyStore.commit('set_flashMessage', {
              show: true,
              level: 'warn',
              header: 'Add Failed',
              message: ''
            })
          } else {
            // console.log(data)
            AmplifyStore.commit('set_flashMessage', {
              show: true,
              level: 'success',
              header: 'Icon Saved!',
              message: that.iconName + ' saved to database'
            })
            that.iconName = ''
            that.iconData = ''
            AmplifyStore.dispatch('admin/getAllIcons')
          }
        })
      }

    },
    findDuplicates(slug){

    }

  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    allIcons() { return AmplifyStore.state.admin.allIcons },
    sortedIcons () {
      let copy = Array.from(this.allIcons).slice()
      return copy.sort(function(a, b) {
        var textA = a.slug.toLowerCase();
        var textB = b.slug.toLowerCase();
        return (Number(textA.match(/(\d+)/g)[0]) - Number((textB.match(/(\d+)/g)[0])));
      });
    }
  }
}
</script>

<style lang="scss" scoped>

  .error{
    border: 1px solid red;
    background-color: red
  }
  .iconInput {
    width: 100%;
    height: 40rem;
  }

  #createLeft {
    align-self: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem;
  }

  .icon-block {
    flex-basis: 90px;
  }
  .icon-wrapper {
    
  }

  :deep(svg) {
    width: 100%;
  }

</style>
