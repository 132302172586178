<template>
    <Header/>
    <Flash/>
    <router-view />
    <Footer/>
</template>

<script>
import Vue from 'vue'
import Header from '@/components/Header'
import Flash from '@/components/Flash'
import Footer from '@/components/Footer'


// window.LOG_LEVEL = 'VERBOSE';

export default {
  name: 'app',
  components: { Header, Flash, Footer },
}
</script>

<style lang="scss">

</style>
