<template>
  <div id="auth" class="bodySpacer">
    <section id="top">
        <!-- <img class="stat" src="/images/stat.svg"> -->
         <div class="stat">
          <blockquote>Helping you build healthy money habits.</blockquote>
         </div>
        <div id="authInputs" class="mainAuth">
          <form v-if="showLogin" @keyup.enter="doLogin">
            <h2>Login to Money Sanity U</h2>
            <input type="email" name="username" id="loginUser" v-model="userEmail" placeholder="Email Address" />
            <div class="passwordWrap">
              <input :type="isMasked ? 'password': 'text'" name="password" id="loginPass" v-model="userPassword" placeholder="Password" />
              <div class="icon" @click="toggleMask">
                <i class="fa-solid fa-eye" v-if="isMasked"></i>
                <i class="fa-solid fa-eye-slash" v-if="!isMasked"></i>
              </div>
            </div>
            <div class="loginFooter">
              <a href="#" @click.prevent="showLogin = false">Reset password</a>
              <div class="button" data-btntext="Sign In" data-btnTextHover="Sign In" @click="doLogin">&nbsp;</div>
            </div>
          </form>
          <form v-if="!showLogin">
            <h2>Reset Your Password</h2>
            <input type="email" name="username" id="loginUser" v-model="userEmail" placeholder="Email Address" />
            <div class="loginFooter">
              <a href="#" @click.prevent="showLogin = true">Back to Sign In</a>
              <div class="button" data-btnText="Send Code" data-btnTextHover="Send Code" @click="doRestPassword">&nbsp;</div>
            </div>
          </form>
          <div class="error" v-if="amplifyError">{{ amplifyError }}</div>
          <div class="loginHelp">
            <div class="toggle" @click.prevent="toggleHelp()">I need help logging in</div>
          </div>
        </div>
        
    </section>
    <welcome signInType="normal" />
    <div id="modal" v-if="helpShowing == true">
      <div id="modalContent" class="isHelp">
        <div class="closeBtn" @click="toggleHelp()"></div>
          <div class="helpContent">
            <h2>Login Help</h2>

              <div class="left">
                <p><strong>If this is your first time accessing Money Sanity U</strong>, Login with your email address and the temporary password included in the invitation email sent to you from contact@sharesavespend.com. We suggest searching your spam folder as well.</p>
                <p><strong>If you can’t find your invitation email or you received the invitation more than 90 days ago</strong> fill out this form so we can help.</p>
                <p><strong>If you receive an error that your password cannot be reset</strong>, please fill out this form and we'll be in touch.</p>
              </div>
              <div class="right" v-if="!helpForm.showThanks">
                <div class="inputWrap" v-bind:class="helpForm.firstName != '' ? 'happy':''">
                  <label>First Name</label>
                  <input type="text" v-model="helpForm.firstName" autocomplete="off">
                </div>
                <div class="inputWrap" v-bind:class="helpForm.lastName != '' ? 'happy':''">
                  <label>Last Name</label>
                  <input type="text" v-model="helpForm.lastName" autocomplete="off">
                </div>
                <div class="inputWrap" v-bind:class="helpForm.email != '' ? 'happy':''">
                  <label>Email Address</label>
                  <input type="email" v-model="helpForm.email" autocomplete="off">
                </div>
                <div class="inputWrap" v-bind:class="helpForm.org != '' ? 'happy':''">
                  <label>Invite Organization</label>
                  <input type="text" v-model="helpForm.org" autocomplete="off">
                </div>
                <div class="button" data-btnText="Get Help" data-btnTextHover="Send it" v-bind:class="helpForm.firstName == '' || helpForm.lastName == '' || helpForm.email == '' || helpForm.org == '' ? 'disabled':''" @click.prevent="sendHelpEmail()">&nbsp;</div>
              </div>

              <div class="right" v-if="helpForm.showThanks">
                <h4>Help Request Sent</h4>
                <!-- <p>We're sorry your experiencing difficulty logging into Money Sanity U. We'll look into the problem on our end and be in touch via the email address you provided.</p> -->
                <p>We’ve received your help request and will be in touch via the email address you provided.</p>
                <p>Thanks for reaching out!</p>
              </div>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { components } from './authenticator';
// import { AmplifyEventBus, AmplifyPlugin } from 'aws-amplify-vue';
import welcome from './Welcome'
import { Auth } from 'aws-amplify'
import AmplifyStore from '../store/store'
import axios from 'axios'
import router from '../router'


export default {
  name: 'Login',
  // props: ['authConfig'],
  components: {welcome},
  data () {
    return {
        user: {
          username: null
        },
        isMasked: true,
        displayMap: {},
        amplifyError: false,
        logger: {},
        verNum: null,
        helpShowing: false,
        userEmail: '',
        userPassword: '',
        showLogin: true,
        helpForm: {
          firstName: '',
          lastName: '',
          email: '',
          org: '',
          formError: false,
          showThanks: false,
        },
        ses: null,
    }
  },
  computed: {

  },
  async mounted() {

  },
  methods: {
    toggleMask:function(){
      this.isMasked = !this.isMasked;
    },
    toggleHelp:function(){
      this.helpShowing = !this.helpShowing;
      this.helpForm.firstName = '';
      this.helpForm.lastName = '';
      this.helpForm.email = '';
      this.helpForm.org = '';
      this.helpForm.showThanks = false;
    },
    sendHelpEmail: function(){
        let that = this;
        let theMessage = "\n I'm experiencing login issues: \n" + 
        this.helpForm.firstName + " " + this.helpForm.lastName + "\n" +
        this.helpForm.email + "\n" +
         "Organization: " + this.helpForm.org + "\n \n";

        const parms = {
          _replyTo: this.helpForm.email,
          _subject: 'Money Sanity U Login Help',
          name: this.helpForm.firstName + ' ' + this.helpForm.lastName,
          message: theMessage
        }
        axios.post('https://formspree.io/f/xjvpelqj', parms
          ).then(function(r){
            if(r.data.ok){
              that.helpForm.showThanks = true;
            } else {
              console.log('something happened');
            }
          }).catch(function(e){
            // window.console.log(e.response)
          });
    },
    async doLogin() {
      if(this.userEmail && this.userPassword) {
        try {
          const user = await Auth.signIn(this.userEmail, this.userPassword);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            console.log ('requires password')
            AmplifyStore.commit('setUser', user);
            router.push({ path: '/auth/newpassword' })
          }
        } catch (error) {
          this.amplifyError = error.message
        }
      }
    },
    async doRestPassword() {
      if(this.userEmail) {
        try {
          console.log("send reset")
          await Auth.forgotPassword(this.userEmail);
          router.push({ path: '/auth/resetpassword' })
        } catch (error) {
          console.log (error.message)
        }
      }
    }
  }
}

</script>

<style>
  .passwordWrap {
    position: relative;
  }
  .passwordWrap .icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
</style>