<template>
  <div id="setupAccount" class="bodySpacer">
    <section class="top">
        <div class="left">
          <div class="circle"><img src="/images/locked-user.svg"></div>
        </div>
        <div class="right">
          <h2>Great! Just one more thing.</h2>
          <p>Create your permanent password following the instructions below to have full access to Money Sanity U.</p>
        </div>
    </section>
    <section>
        <div class="newPassInner">
          <div class="inputWrap">
            <label for="">Choose a new password</label>
            <input v-model="password" type="password" placeholder="Password" autocomplete="off" @keyDown="validatePassword" tabindex="3" />
          </div>
            <strong>Must contain at least:</strong>
            <ul class="passwordHelp">
              <li v-bind:class="{valid: hasLength}">At least 8 characters long</li>
              <li v-bind:class="{valid: hasUpper}">1 capital or more Letters</li>
              <li v-bind:class="{valid: hasNumber}">1 or more numbers</li>
              <li v-bind:class="{valid: hasSpecial}">1 or more special characters (!@#$%)</li>
            </ul>
        </div>
    </section>
    <!-- <section>
      <h3>Let's get to know you <span>(optional)</span></h3>
      <p>If you share a bit about your background, we can show content that's likely to be more relevant to you.</p>
      <div class="inputWrap">
        <label for="">What's your life stage?</label>
        <select name="" id="">
          <option disabled selected value="">Please select one</option>
          <option>Just starting out</option>
          <option>Mid career</option>
          <option>Nearing retirement</option>
          <option>Enjoying retirement</option>
        </select>
      </div>
      <div class="inputWrap">
        <label>I am/have (select all that apply)</label>
        <ul>
          <li><label for="married"><input type="checkbox" name="married" id=""> <span>Currently Married</span></label></li>
          <li><label for="babies"><input type="checkbox" name="babies" id=""> <span>Very young children</span></label></li>
          <li><label for="kids9-12"><input type="checkbox" name="kids9-12" id=""> <span>Elementry age children</span></label></li>
          <li><label for="kids13-15"><input type="checkbox" name="kids13-15" id=""> <span>Early teen age children</span></label></li>
          <li><label for="kids16-19"><input type="checkbox" name="kids16-19" id=""> <span>Late teen age children</span></label></li>
        </ul>
      </div>
    </section> -->

    <div class="buttonWrap">
        <div class="button" data-btnText="Finalize Account" data-btnTextHover="Finalize Account" :class="!hasUpper || !hasSpecial || !hasNumber || !hasLength ? 'disabled':''" @click="setNewPassword()">&nbsp;</div>
      </div>

  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import router from '../router'
import { Auth } from 'aws-amplify'

export default {
  name: 'NewPassword',
  data () {
    return {
      password: '',
      hasUpper: false,
      hasSpecial: false,
      hasNumber: false,
      hasLength: false,
      errorMessage: '',
    }
  },
  created () {

  },
  methods: {
    validatePassword: function() {
      this.hasUpper = false
      this.hasSpecial = false
      this.hasNumber = false
      this.hasLength = false

      const lowerCaseLetters = /[a-z]/g,
            upperCaseLetters = /[A-Z]/g,
            numbers = /[0-9]/g,
            specials = /[!@#$%^&*\[\(\)\]\-_\+\=]/g

      if(this.password.match(upperCaseLetters)) this.hasUpper = true
      if(this.password.match(specials)) this.hasSpecial = true
      if(this.password.match(numbers)) this.hasNumber = true
      if(this.password.length > 7 && this.password.length < 100) this.hasLength = true
    },
    async setNewPassword(){
      try {
        const loggedInUser = await Auth.completeNewPassword(
          this.user, // the Cognito User Object
          this.password, // the new password
        )
        // console.log(loggedInUser)
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'success',
          header: 'Your account was created!',
          message: 'Choose an interesting track to get started.'
        })
        router.push({path: '/dashboard'})
      }
      catch(error) {
        console.log('New Password Error ' + error)
      }
    }
    // setNewPassword() {
    //   let that = this;
    //   this.user.completeNewPasswordChallenge(this.password, this.user.challengeParam.requiredAttributes, {
    //     onSuccess: function(result) {
    //       AmplifyStore.commit('set_flashMessage', {
    //         show: true,
    //         level: 'success',
    //         header: 'Your account was created!',
    //         message: 'Choose an interesting track to get started.'
    //       })
    //       that.$router.push({path: '/dashboard'})
    //       return
    //     },
    //     onFailure: function(error) {
    //       console.error('failed', error)
    //       return
    //     }
    //   })
    // }
  },
  computed: {
    user() { return AmplifyStore.state.user },
  },
  watch: {
    'password'() {
      this.validatePassword()
    }
  }
}
</script>

<style scoped>
</style>
