<template>
    <!-- <div id="auth" class="bodySpacer"> -->
    <section id="top">
      <div class="processing">
          <div class="inner">
            <div class="spinner"></div>
            <div class="text">Signing in to Money Sanity U</div>
          </div>
      </div>
    </section>
  <!-- </div> -->
</template>

<script>
export default {
    name: 'authRedirect',
    data () {
      return { }
    },
    computed: {
      
    },
    mounted() {
    
    },
    methods: {
    }
}
</script>

<style scoped>

</style>