<template>
  <div class="admin bodySpacer verticalSpacer">

    <div class="adminBlock">
      <div class="adminHeader">
        <h2>Groups</h2>
        <div class="linkList" v-if="allGroups">
          <div v-for="g in allGroups" class="groups" v-bind:class="g.groupStatus">
            <router-link :to="{ name: 'AdminGroup', params: {slug: g.slug} }">
              {{g.title}}
            </router-link>
          </div>
        </div>
        <div class="newGroup">
          <input type="text" v-model="createGroupName" placeholder="Group Name"/>
          <input type="text" v-model="createGroupManager" placeholder="Manager Email"/>
          <label>Max Members (0 is unlimited)</label>
          <input type="number" v-model="createGroupMax"/>
          <button @click="createGroup()">Create Group</button>
        </div>
      </div>
    </div>

    <div class="adminBlock">
      <h2>Tracks</h2>

      <div class="linkList" v-if="allTracks">
        <div v-for="track in sortedTracks" class="content" v-bind:class="track.trackDisabled ? 'disabled' : ''">
          <router-link :to="{ name: 'AdminUpdateTrack', params: {slug: track.slug} }">
            {{track.title}}
          </router-link>
        </div>
      </div>
      <router-link tag="button" class="fab" to="/admin/createtrack">
        New Track
      </router-link>
    </div>

    <div class="adminBlock">
      <h2>Learning Units</h2>

      <div class="linkList" v-if="allLearningUnits">
        <div v-for="unit in sortedUnits" class="content" v-bind:class="[ unit.unitDisabled ? 'disabled' : '', unit.type ]">
          <router-link :to="{ name: 'AdminUpdateUnit', params: {unitId: unit.unitId} }">
            {{unit.title}}
          </router-link>
        </div>
      </div>
      <router-link tag="button" class="fab" to="/admin/createunit">
        New Unit
      </router-link>
    </div>


    <!-- <h2>Users</h2>

    <button @click="getUserList(user.signInUserSession.idToken.payload.email)">Get Users</button>
    <div v-if="userList" v-for="u in userList">
      {{u.Username}} |
      {{u.UserStatus}} |
      {{convertArrayToObject(u.Attributes)["email"]}}
      <button v-if="u.Enabled" @click="disableUser(u.Username)">DISABLE USER</button>
      <button v-if="!u.Enabled" @click="enableUser(u.Username)">ACTIVATE USER</button>
      <button v-if="!u.Enabled" @click="deleteUser(u.Username)">DELETE USER</button>
    </div>



    <input type="email" v-model="createUserEmail" placeholder="Email"/>
    <select v-model="createAccountType">
      <option disabled value="null">Select account Type</option>
      <option value="user">User</option>
      <option value="manager">Manager</option>
    </select>
    <button @click="createUser()">Add User</button>

    <h2>Managers</h2>
    <div v-if="managerList" v-for="u in managerList">
      {{u.Username}} |
      {{u.UserStatus}} |
      {{convertArrayToObject(u.Attributes)["email"]}}
    </div> -->

  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'Dashboard',
  data () {
    return {
      createGroupName: '',
      createGroupMax: 0,
      createUserEmail: '',
      createGroupManager: '',
      createAccountType: null,
    }
  },
  created () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')

    // Initialize admin
    if(this.user.attributes["custom:role"] === "admin"){
      AmplifyStore.dispatch('admin/listUsersInCognitoGroup')
      AmplifyStore.dispatch('admin/getAllGroups')
      AmplifyStore.dispatch('admin/getAllLearningUnits')
    }

    // Initialize manager
    if(this.user.attributes["custom:role"] === "manager"){
      AmplifyStore.dispatch('admin/getGroupBySlug', this.user.attributes["custom:groupSlug"])
    }
  },
  unmounted () {
    AmplifyStore.commit('admin/set_allGroups', false)
    AmplifyStore.commit('admin/set_userList', false)
    AmplifyStore.commit('admin/set_allLearningUnits', false)
  },
  methods: {
    disableUser: function(username) {
      AmplifyStore.dispatch('admin/disableUser', { username: username })
    },
    enableUser: function(username) {
      AmplifyStore.dispatch('admin/enableUser', { username: username })
    },
    deleteUser: function(username) {
      AmplifyStore.dispatch('admin/deleteUser', { username: username })
    },
    getUserList: function(manager) {
      AmplifyStore.dispatch('admin/getUserList', manager)
    },
    createUser: function() {
      let params = {
        username: this.createUserEmail,
        role: this.createAccountType
      }

      AmplifyStore.dispatch('admin/createUser', params)
    },
    createGroup: function() {
      if(!this.createGroupName){
        //console.log("Group Name required")
        return
      }
      if(!this.createGroupManager){
        //console.log("Manager Email Required")
        return
      }

      let groupParams = {
        "Item": {
          title: this.createGroupName,
          maxMembers: this.createGroupMax,
          groupStatus: 'active',
          manager: this.createGroupManager,
          members: []
        }
      }

      let userParams = {
        "username": this.createGroupManager,
        "role": 'manager',
        "group": this.createGroupName
      }

      AmplifyStore.dispatch('admin/createGroup', groupParams)
        .then(() => {
          AmplifyStore.dispatch('admin/createUser', userParams)
        })
    },
    convertArrayToObject: function(arr) {
      let obj = arr.reduce(function(acc, cur, i) {
        acc[cur.Name] = cur.Value;
        return acc;
      }, {});

      return obj
    }
  },
  computed: {
    user() { return AmplifyStore.state.user },
    userList() { return AmplifyStore.state.admin.userList },
    managerList() { return AmplifyStore.state.admin.managerList },
    allGroups() { return AmplifyStore.state.admin.allGroups },
    allLearningUnits() { return AmplifyStore.state.admin.allLearningUnits },
    allTracks() { return AmplifyStore.state.private.allTracks },
    sortedTracks(){
      if(this.allTracks){
        return this.allTracks.sort((a,b) =>{
          return dayjs().utc(b.createdAt).diff(dayjs().utc(a.createdAt))
        });
      }
      return []
    },
    sortedUnits(){
      if(this.allLearningUnits){
        return this.allLearningUnits.sort((a,b) =>{
          return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        });
      }
      return []
    },
    userProgress() { return AmplifyStore.state.private.userProgress },
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }

  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
