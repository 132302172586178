<template>

    <div id="userGroup" class="bodySpacer verticalSpacer admin" v-if="group && group.groupStatus" v-bind:class="group.groupStatus">
      <div id="adminHead" v-if="group && group.members && user.attributes['custom:role']=='admin'">
          <h2>Manage Org: {{group.title}}</h2>
      </div>
      <div id="tabs" >
        <div class="tab" :class="activeTab == 1 ? 'active':''" @click="activeTab = 1">Manage Users</div>
        <div class="tab" v-if="sortedResources.length > 0" :class="activeTab == 2 ? 'active':''" @click="activeTab = 2">Resources</div>
        <div class="tab" v-if="user.attributes['custom:role']=='admin'" :class="activeTab == 3 ? 'active':''" @click="activeTab = 3">Analytics</div>
      </div>
      <div class="flex-wrap">
        <section id="createLeft" class="adminBlock groupMain" v-if="group && group.members" v-bind:class="user.attributes['custom:role']=='manager' ? 'wrapped':''">
          <!-- Users list -->
          <div v-if="activeTab == 1">
            <h6>{{group.members.length}} of <span v-if="group.maxMembers">{{group.maxMembers}} Users</span><span v-else>Unlimited Users</span>
            <div class="tools">
              <button @click="showUpload()" v-if="!isSSO && user.attributes['custom:role']=='manager' || user.attributes['custom:role']=='admin'">Batch Add</button>
              <button @click="exportToCsv()">Export Members</button>
            </div>
            </h6>
            <!-- <div v-if="!group.members.length">
              No members
            </div> -->
            <div class="addMember inputWrap flex-wrap" v-if="!isSSO">

                <input type="email" v-model="createUserEmail" placeholder="Email Address"/>
                <input type="text" v-model="createUserFirst" placeholder="First Name">
                <input type="text" v-model="createUserLast" placeholder="Last Name">


                <!-- /////
                Hidden to allow bug fixes
                - make sure to change this.createAccountType back to 'null'
                ///// -->


                <select v-if="user.attributes['custom:role']=='admin'" v-model="createAccountType">
                  <option disabled value="null">Type</option>
                  <option value="user">User</option>
                  <option value="manager">Manager</option>
                </select>
              <div class="addAction">
                <button @click="addGroupMember()">Add</button>
              </div>
            </div>

            <div class="groupsNav" v-if="group.subGroups">
              <div class="groupTab" 
                @click="displaySubGroup('default')"
                v-bind:class="subGroupDisplay == 'default' ? 'active':''"
              >None</div>
              <div class="groupTab" 
                v-for="sg in group.subGroups" 
                v-bind:key="sg.name + '_nav'"
                v-bind:class="subGroupDisplay == sg.name ? 'active':''"
                @click="displaySubGroup(sg.name)"
              >{{sg.name}}</div>
            </div>

            <div class="memberList" v-show="subGroupDisplay=='default'">
              <div v-for="m in sortedMembers" :key="m.sub" class="memberRow">
                <div v-if="!m.subGroupId" v-bind:class="{isManager: group.manager.includes(m.email)}">
                  <span class="email" @click="managerGetUser(m.sub)">
                    <span v-if="m.email">{{m.email}}</span> 
                    <span v-if="!m.email">External User: {{m.sub}}</span> 
                    <span v-if="m.first && m.first !='not_given'"> - {{m.first}}&nbsp;</span>
                    <span v-if="m.last && m.last !='not_given'">{{m.last}}</span>
                  </span>
                  <span v-if="user.attributes['custom:role']=='admin'" class="lastLogin">{{ showLastLogin(m) }}</span>
                  <select v-model="m.subGroupId" @change="updateUser(m)" v-if="group.subGroups && !group.manager.includes(m.email)">
                      <option disabled :value="undefined">Move User</option>
                      <option v-for="sg in group.subGroups" :value="sg.id" :key="sg.id">{{sg.name}}</option>
                    </select>
                  <span class="action" v-if="!group.manager.includes(m.email) || user.attributes['custom:role']=='admin'">
                    <button v-if="!isSSO" class="small" @click="deleteGroupMember(m.sub, m.email)">Delete</button>
                  </span>
                </div>
              </div>
            </div>
              
            <div v-for="sg in group.subGroups" v-bind:key="sg.name" class="memberList" v-show="subGroupDisplay==sg.name">
              <!-- <div v-if="!editingSubGroups" >
                <span>{{sg.name}}</span>
                <span>Layout: {{sg.settings}}</span>
                <button class="small" @click="editingSubGroups = true">Edit</button>
              </div> 
              
              <div v-if="editingSubGroups" >
                Name:
                <input type="text" v-model="sg.name" />
                Layout:
                <select v-model="sg.settings">
                  <option v-for="s in allSettings" :value="s.config">{{s.config}}</option>
                </select>
                <button class="small" @click="updateSubGroups(group.subGroups)">Save</button>
              </div> -->
              <div class="groupControls">
                <select v-model="sg.settings">
                  <option v-for="s in allSettings" :value="s.config">{{s.config}}</option>
                </select>
              </div>

              <div v-for="m in sortedMembers.map(sm => sm)" :key="m.email" class="memberRow">
                <div v-if="m.subGroupId === sg.id">
                  <span class="email" @click="managerGetUser(m.sub)">{{m.email}} <span v-if="m.first"> - {{m.first}} </span><span v-if="m.last">{{m.last}} </span></span>
                  <select v-model="m.subGroupId" @change="updateUser(m)" v-if="!group.manager.includes(m.email)">
                    <option disabled selected :value="undefined">Move User</option>
                    <option v-for="sg in group.subGroups" :value="sg.id" :key="sg.id">{{sg.name}}</option>
                  </select>
                  <span class="action" v-if="!group.manager.includes(m.email) || user.attributes['custom:role']=='admin'">
                    <button class="small" @click="deleteGroupMember(m.sub, m.email)">Delete</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Resources list -->
          <div v-if="activeTab == 2" class="groupResources">
            <div class="info">
              The success of implementing Money Sanity U&reg; often hinges on an organizations ability to shine a spotlight on the platform and its value for their users. Below are a few tools to help you fully maximize your Money Sanity U launch and promote the ongoing benefits and updates on the platform.
            </div>
            <h3>Available Files</h3>
            <ul class="resourceList layout" v-if="allResources">
              <li v-for="r in sortedResources" :key="r.resouceId">
                <div class="overview">
                  <!-- <div class="overview" :class="r.forGroups === 'all' ? 'global':''"> -->
                  <h4>{{r.displayName}}</h4>
                  <p v-if="r.description && r.description != ''">{{r.description}}</p>  
                </div> 
                <div class="actions">
                 <button @click="showResource(r.resourceName)">Download</button>
                </div>
              </li>
            </ul>
          </div>

          <div class="stats" v-if="activeTab == 3">
            <GroupStats :groupName="group.slug" :memberCount="group.members.length" />
          </div>
          
        </section>


        <aside class="groupSidebar" v-if="user.attributes['custom:role']=='admin'">
          <!-- <h5>Managed By:</h5>
          <div><a v-bind:href=" `mailto:${group.manager}` ">{{group.manager}}</a></div> -->
          <h5>Display Name <span class="help"> <span class="detail">Used when communicating with users. If blank, the system org name will be used.</span></span></h5>
          <div class="inputWrap adminSidebarWrap">
            <input type="text" v-model="groupDisplayName">
            <div>
              <button @click="updateDisplayName()" :disabled="groupDisplayName === group.groupDisplayName" >Update</button>
            </div>
          </div>

          <h5>Custom Branding: <span class="help"> <span class="detail">You can upload an image to be used in the header for this group. Horizontal images with either a white or transparent background work best.</span></span></h5>
          <div class="brandImage" v-if="groupImage" v-bind:key="groupImage">
            <div @click="showPreviewModal()">
              <SSSImage :imagePath="groupImage" ></SSSImage>
            </div>
            <button class="small" @click="deleteGroupImage()">Delete</button>
          </div>
          <div class="inputWrap adminSidebarWrap customBranding">
            <input class="fileInput" type="file" id="file" name="file" ref="file" v-on:change="getAttachedFile()"/>
            <label for="file">{{fileInputValue}}</label>
            <div>
              <button class="uploadAction" @click="uploadGroupImage()" :disabled="!groupFile" >{{groupImage ? 'Replace Image' : 'Upload Image'}}</button>
            </div>
          </div>
          <div class="inputWrap adminSidebarWrap customURL" v-if="groupImage">
            <input type="text" name="customURL" placeholder="Link Image to URL" v-model="groupLinkTo">
            <div>
              <button @click="updateGroupLinkTo()" :disabled="groupLinkTo === group.groupLinkTo">Update</button>
            </div>
          </div>

          <h5>Default Layout: <span class="help"><span class="detail">This controls which tracks and topics are visible to members of this Org. Can be overridden by group settings.</span></span></h5>
          <div class="inputWrap">
            <select v-model="selected" @change="updateGroupSettings">
            <option v-for="s in allSettings" :value="s.config">{{s.config}}</option>
          </select>
          </div>
          <!-- <button :disabled="selected === this.group.settings" @click="updateGroupSettings">Save</button> -->

          <!-- <h5>Manage User Groups: <span class="help"><span class="detail">Separate users into groups with unique layout preferences.</span></span></h5>
          <div class="inputWrap">
            <label>Create Group:</label>
            <input type="text" placeholder="New Group Name" v-model="createSubGroupName"> 
            <button class="small" :disabled="!createSubGroupName" @click="createSubGroup()">Create</button>
          </div> -->
          <h5>Org Status: ({{group.groupStatus}})</h5>
          <div class="toggle">
            <button @click="updateGroupStatus('active')" v-if="group.groupStatus !== 'active'">Activate</button>
            <button @click="updateGroupStatus('disabled')" v-if="group.groupStatus !== 'disabled'">Deactivate</button>
          </div>

          <!-- <h5>Admin Stuff</h5>
          <div><button @click="updateAllInGroup()">Add All to org</button></div> -->
        </aside>
      </div>

      <div id="bulkImportStatus" v-bind:class="dataFromCsv.length && createdUsersResults.length > 0 ? 'active':''">
        <i class="far fa-times-circle closeBtn"  @click="resetImport" v-if="percentDone() == '100%'"></i>
        <div v-if="createdUsersResults.length" class="resultList" v-bind:class="showImportResults ? 'showResults' : ''">
          <div class="row" v-for="(res, i) in createdUsersResults" :key="i" v-bind:class="res.message === 'Created' ? 'success':'error'">
            <strong>{{res.user.username}}</strong>
              <span class="user" v-if="res.user.first || res.user.last"> for {{res.user.first}} {{res.user.last}}</span>
              <span class="message">{{res.message}}</span> 
          </div>
        </div>

        <h3>Importing Users to {{group.title}}
          <span>
            <span class="pass">Succeeded: {{successCreationCount}}</span> &nbsp; 
            <span v-if="numberFailed() != 0" @click="toggleResults" class="failed">Failed: {{numberFailed()}}</span> 
          </span>
        </h3>
        <div class="progressBar">
          <div class="barInner" v-bind:style="{width: percentDone()}"></div>
        </div>
      </div>

      <!-- User info modal -->
      <div id="modal" v-if="member && showUserModal == true && !isSSO">
        <div id="modalContent" class="userDetailsModal">
          <div class="closeBtn" @click="hideUserModal()"></div>
          <Member :sub="member"></Member>
        </div>
      </div>

      <!-- Header preview modal -->
      <div id="modal" v-if="showPreview == true">
        <div id="modalContent" class="headerPreview">
          <div class="closeBtn" @click="hidePreviewModal()"></div>
            <div class="brandImagePreview">
                <header>
                  <div class="bodySpacer">
                    <div class="mainLogo">
                      <h1>Money Sanity U</h1>
                    </div>
                    <headerLogo :imagePath="groupImage" :groupTitle="group.title" ></headerLogo>
                    <nav class="userNav">
                      <div class="links">
                        <a href="#" class="">Topics</a>
                        <a href="#" class="">My Progress</a>
                        <a href="#" class="">View All</a>
                        <a href="#">Sign Out</a></div>
                    </nav>
                  </div>
                </header>
            </div>
        </div>
      </div>

      <!-- Batch upload users modal -->
      <div id="modal" v-if="showModalUpload == true && !isSSO">
        <div id="modalContent" v-bind:class="showPreview ? 'headerPreview':''">
          <div class="closeBtn" @click="hideModalUpload()"></div>
          <h2 class="importHeader">Bulk Invite Users</h2>
          <div class="importUsers">
            <div v-if="!dataFromCsv.length && !isUploadingCsv" class="step1">
              <div class="leftSide">
                <div class="uploadError" v-if="csvError != ''">
                  <h5>{{csvError}}</h5>
                  <p>Please check the CSV formatting and try again.</p>
                </div>
                <h3>Step 1 of 2 - Upload CSV File</h3>
                <div class="fileSelect">
                  <input class="fileInput" type="file" id="csv" name="csv" ref="csv" accept=".csv" v-on:change="getImportCSV()"/>
                  <label for="csv">{{csvFileName}}</label>
                  <button class="uploadAction" @click="uploadCSV()" :disabled="!importCsvFile">Import</button>
                </div>
              </div>
              <aside>
                <p class="info">Money Sanity U accepts <strong>ONLY CSV Files</strong> containing an email address for each user and optional first and last names. To ensure proper upload, use the sample CSV file below to create your list.</p>
                <!-- Hosting with the html files failed. Moved to S3 bucket to make Amplify happy -->
                <a href="https://msu-client-assets.s3.us-east-2.amazonaws.com/public/resources/exampleUserImport.csv" download="Example_User_List.csv" class="csvExample"><i class="far fa-file-spreadsheet"></i> Download CSV</a>
              </aside>
            </div>

            <div v-if="dataFromCsv.length && !isUploadingCsv" class="step2">
              <div class="leftSide">
                <h3>Step 2 of 2 - Generate Invites</h3>
                <div class="importInfo">
                  <h5>CSV contained {{dataFromCsv.length}} emails and {{errorDataFromCsv.length}} Errors</h5>
                  <div class="errorList" v-if="errorDataFromCsv.length > 0">
                    <div class="error" v-for="(u, i) in errorDataFromCsv" :key="i">
                      <div v-if="u.email != ''"><strong>{{u.email}}</strong> - {{u.error}}</div>
                      <div v-else><strong>{{u.first}} {{u.last}}</strong> - {{u.error}}</div>
                    </div>
                  </div>
                  <span><em>Note:</em> Emails will generate system invites for "basic" users.</span>
                </div>
              </div>
              <aside>
                <p class="info">This action may take a while. <strong>You cannot cancel the process once it's started</strong> and leaving this screen will stop the process!</p>
              </aside>
              <div class="buttonWrap">
                <button class="uploadAction" @click="createUsersFromCSV()">Invite {{dataFromCsv.length}} Users</button>
                <a href="#" @click.prevent="hideModalUpload()">Cancel</a>
              </div>
            </div>

            <div class="processing" v-if="isUploadingCsv">
                <div class="inner">
                  <div class="spinner"></div>
                  <div class="text">Processing CSV File...</div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
import { Storage, API } from 'aws-amplify'
import uuid from 'uuid'
import queue from 'async/queue';
import AmplifyStore from '@/store/store'
import SSSImage from '@/components/SSSImage'
import Member from '@/components/admin/components/Member'
import GroupStats from '@/components/admin/components/GroupStats'
import headerLogo from '@/components/headerLogo'
import Vue from 'vue'
import * as dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  name: 'Group',
  props: ['slug'],
  components: { SSSImage, Member, headerLogo, GroupStats },
  data () {
    return {
      fileInputValue: 'Select A File',
      csvFileName: 'Select CSV File',
      groupImage: false,
      groupFile: false,
      groupDisplayName: '',
      groupLinkTo: null,
      importCsvFile: false, // file from file picker to be imported
      createdUsersFromCsv: [], // all successful creations to add to group members
      createdUsersResults: [], // all results from attempted creations
      dataFromCsv: [], // data parsed from csv file via lambda
      errorDataFromCsv: [],
      createAccountType: 'user',
      createUserEmail: '',
      createUserFirst: '',
      createUserLast: '',
      showUserModal: false,
      member: null,
      selected: false,
      showPreview: false,
      showImportResults: false,
      showModalUpload: false,
      isUploadingCsv: false,
      csvError: '',
      editingSubGroups: false,
      createSubGroupName: '',
      subGroupDisplay: 'default',
      isSSO: false,
      activeTab: 1,
      publicPath: process.env.BASE_URL,
    }
  },
  created () {
    AmplifyStore.dispatch('admin/getAllResources')
  },
  mounted () {
    if(!this.allSettings && this.user.attributes["custom:role"] === 'admin') AmplifyStore.dispatch('admin/getAllSettings')
    if(this.user.attributes["custom:role"] === 'manager' && this.user.attributes["custom:groupSlug"] === this.slug){
      AmplifyStore.dispatch('admin/getGroupBySlug', this.slug)
        .then(() => {
          AmplifyStore.dispatch('admin/batchGetGroupUsers', this.group.members)
        })
    }
    if(this.user.attributes["custom:role"] === 'admin'){
      AmplifyStore.dispatch('admin/getGroupBySlug', this.slug)
        .then(() => {
          this.selected = this.group.settings || 'default'
          AmplifyStore.dispatch('admin/batchGetGroupUsers', this.group.members)
        })
    }
    if (this.slug == 'medica' || this.slug == 'fci'){
      this.isSSO = true;
    }

    if (this.user && this.user.attributes['custom:role']!='admin'){
      // this.$plausible.trackPageview()
    }

  },
  methods: {
    updateUser: function(user){
      console.log(user)
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}Users`,
        Key: {'user': user.sub},
        UpdateExpression: 'set subGroupId = :val',
        ExpressionAttributeValues: { ":val": user.subGroupId }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/batchGetGroupUsers', that.group.members)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Action complete',
            message: `${user.email} sub group updated`
          })
        }
      });
    },
    // updateUserToGroup: function(user) {
    //   let params = {
    //     TableName : `${this.ENV.tablePrefix}Users`,
    //     Key: {'user': user.sub},
    //       UpdateExpression: 'set org = :val',
    //       ExpressionAttributeValues: { ":val": this.group.slug }
    //   }
    //   this.docClient.update(params, function(err, data) {
    //     if (err) {console.log(err)}
    //     else {
    //       console.log('updated: ' + user.sub)
    //     }
    //   })
    // },
    // used to update user records for to associate with groups
    // updateAllInGroup: function(){
    //   this.sortedMembers.forEach(m => this.updateUserToGroup(m))
    // },
    createSubGroup: function(){
      let that = this
      let subGroups = []
      let newSubGroup = {
        id: uuid.v1(),
        name: this.createSubGroupName,
        settings: 'default'
      }
      
      if(this.group.subGroups){
        subGroups = [...this.group.subGroups, newSubGroup]
      } else {
        subGroups = [newSubGroup]
      }
      
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set subGroups = :val',
        ExpressionAttributeValues: { ":val": subGroups }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Action complete',
            message: `Sub Groups Updated`
          })
        }
      });
      this.createSubGroupName = ''
    },
    updateSubGroups: function(subGroups){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set subGroups = :val',
        ExpressionAttributeValues: { ":val": subGroups }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Action complete',
            message: `Sub Groups Updated`
          })
        }
      });
      this.editingSubGroups = false
    },
    lambdaCreateUser: async function(user){
      // console.log('import')
      let userDetails = {
        first: user.first,
        last: user.last,
        username: user.email.toLowerCase(),
        group: this.group.slug,
        members: this.group.members
      }
      
      let apiName = 'LAMBDA_CREATE_USER';
      let path = '/';
      let myInit = {
          headers: {},
          response: true,
          body: {
              user: userDetails
          }
      }   

      return await API.post(apiName, path, myInit)
      .then(response => {
          console.log('Res', response.data)
          console.log('Submited', userDetails)
          if(response.data.User){userDetails.id = { "email": user.email, "sub": response.data.User.Username, "first": user.first, "last": user.last }
            AmplifyStore.dispatch('admin/createUserProgress', response.data.User.Username)
          }
          return {status: response.data.statusCode, message: response.data.message || 'Created', user: userDetails}

      }).catch(error => {
          console.log('error', error)
          return {status: 400, message: error, user: userDetails}
      });
      
    },
    lambdaCampaignMonitor: async function(params, action){
      let apiName = 'MSU_ADD_TO_CM';
      let path = '/';
      let myInit = {
          headers: {},
          response: true,
          body: {
              user: params,
              type: action
          }
      }
      return await API.post(apiName, path, myInit)
      .then(response => {
          console.log(action + " User " + params.email + " to/from CM")
          return
      });
      
    },
    createUsersFromCSV: function(){
      console.log(this.dataFromCsv)
      let that = this
      let q = queue(function(user, callback) {
          that.lambdaCampaignMonitor(user, 'subscribe').then(res => {
            console.log('XXX Adding user to CM XXX')
            console.log(res)
          })
          that.lambdaCreateUser(user)
            .then(res => callback(res))
      }, 1);
            
      q.push(this.dataFromCsv, function(res) {
          console.log('finished processing item', res);
          if(res.user && res.user.id) that.createdUsersFromCsv.push(res.user.id)
          that.createdUsersResults.push(res)
      });
      
      q.drain = function() {
          console.log('all items have been processed', that.createdUsersFromCsv);
          if(that.createdUsersFromCsv.length){
            let newMembers = that.group.members.concat(that.createdUsersFromCsv)
            let params = {
              manager: that.group.manager,
              group: that.group.slug,
              members: newMembers,
              UpdateExpression: 'set members = :val',
              ExpressionAttributeValues: { ":val": newMembers}
            }
            AmplifyStore.dispatch('admin/updateGroupMembers', params)
              .then(() => {
                that.createdUsersFromCsv = []
              })
          }
      }
      this.showModalUpload = false;
    },
    parseCsv: async function(s3Path){
      let apiName = 'LAMBDA_PARSE_CSV';
      let path = '/';
      let myInit = {
          headers: {},
          response: true,
          body: {
              'Bucket': this.ENV.bucket,
              'Key': s3Path
          }
      }
      
      await API.post(apiName, path, myInit).then(response => {
          console.log('found file to parse')
          console.log(response.data.results)
          
          //const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

          // less restrictive email pattern
          const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

          response.data.results.forEach((res) => {
            if(res.email.match(regex)){
              this.dataFromCsv.push(res)
            } else {
              if(!res.email || res.email === ""){
                res.error = "Email is required"
              } else {
                res.error = "Not a valid email address format"
              }
              this.errorDataFromCsv.push(res)
            }
          })
          
          this.importCsvFile = false
          this.csvFileName = 'Select CSV File'
          this.isUploadingCsv = false

      }).catch(error => {
          this.importCsvFile = false
          this.csvFileName = 'Select CSV File'
          this.isUploadingCsv = false
          this.csvError = 'Failed to process CSV'
          console.log('Unable to find file')
          console.log(error.response)
      });
    },
    exportToCsv: function() {
        let filename = `MSU-${this.group.slug}-export-${Date.now()}` + ".csv"
        let csvFile = this.ConvertToCSV();
        let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
    ConvertToCSV: function() {
        let array = typeof this.sortedMembers != 'object' ? JSON.parse(this.sortedMembers) : this.sortedMembers;
        let str = 'Email,First,Last,Sub,Org,OrgDisplayName,Role,Status' + '\r\n';

        for (let i = 0; i < array.length; i++) {
            let userRow = array[i]
            let sub = userRow.sub
            let uE = userRow.email
            let uF = ''
            let uL = ''
            let uR = 'user'
            let uSubGroup = ''
            let uGroupName = ''
            if(userRow.first){
              uF = userRow.first
            }
            if(userRow.last){
              uL = userRow.last
            }
            if(userRow.subGroupId){
              uSubGroup = this.group.subGroups.find( sg => sg.id === userRow.subGroupId ).name
            }
            if(this.group.groupDisplayName && this.group.groupDisplayName != ''){
              uGroupName = this.group.groupDisplayName
            }
            if(this.group.manager.includes(userRow.email)){
              uR = 'manager'
            }
            str += uE + ',' + uF + ',' + uL + ',' + uSubGroup + ',' + this.group.title + ',' + uGroupName + ',' + uR + ',Active \r\n';
        }
        return str;
    },
    updateGroupSettings: function(){
      console.log(this.selected)
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set settings = :val',
        ExpressionAttributeValues: { ":val": this.selected }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Action complete',
            message: `Settings updated to "${that.selected}"`
          })
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
          .then(() => that.selected = that.group.settings )
          //that.groupImage = `groups/${that.group.slug}/${that.group.groupImage}`
        }
      });
    },
    getImportCSV: function() {
      if(this.$refs.csv) {
        this.importCsvFile = this.$refs.csv.files[0];
        this.csvFileName = this.importCsvFile.name;
      } else {
        this.importCsvFile = false
      }
    },
    getAttachedFile: function() {
      if(this.$refs.file) {
        this.groupFile = this.$refs.file.files[0];
        this.fileInputValue = this.groupFile.name;
      } else {
        this.groupFile = false
      }
    },
    uploadCSV: function(){
      this.isUploadingCsv = true;
      this.csverror = '';
      Storage.put(`groups/${this.group.slug}/${this.importCsvFile.name}`, this.importCsvFile, {
          contentType: this.importCsvFile.type
      })
        .then (result => {console.log(result); this.parseCsv(`public/groups/${this.group.slug}/${this.importCsvFile.name}`);})
        .catch(err => console.log(err));
    },
    uploadGroupImage: function(){
      Storage.put(`groups/${this.group.slug}/${this.groupFile.name}`, this.groupFile, {
          contentType: this.groupFile.type
      })
        .then (result => this.setGroupImageInDB())
        .catch(err => console.log(err));
    },
    deleteGroupImage: function(){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set groupImage = :val',
        ExpressionAttributeValues: { ":val": null }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Action complete',
            message: `Branding removed`
          })
        }
      });
    },
    setGroupImageInDB: function(){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set groupImage = :val',
        ExpressionAttributeValues: { ":val": this.groupFile.name }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          document.getElementById('file').value = null
          that.groupFile = false
          that.fileInputValue = 'Select A File';
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
          //that.groupImage = `groups/${that.group.slug}/${that.group.groupImage}`
        }
      });
    },
    updateDisplayName: function(){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set groupDisplayName = :val',
        ExpressionAttributeValues: { ":val": this.groupDisplayName }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
        }
      });
    },
    updateGroupLinkTo: function(){
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set groupLinkTo = :val',
        ExpressionAttributeValues: { ":val": this.groupLinkTo }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
        }
      });
    },
    managerGetUser: function(sub) {
      let userInGroup = this.group.members.find(m => {
        return m.sub === sub
      })
      this.member = sub
      this.showUserModal = true
    },
    showPreviewModal: function(){
        this.showPreview = true;
    },
    hideUserModal: function(){
      this.showUserModal = false;
    },
    hidePreviewModal: function(){
      this.showPreview = false;
    },
    hideModalUpload: function(){
      this.showModalUpload = false
      this.csvError = ''
    },
    addGroupMember: function() {
      this.createUserEmail = this.createUserEmail.toLowerCase()

      let params = {
        email: this.createUserEmail,
        username: this.createUserEmail,
        manager: this.group.manager,
        group: this.slug,
        members: this.group.members,
        role: this.createAccountType,
        groupDisplayName: this.group.groupDisplayName,
      }
      
      if(this.createUserFirst) params.first = this.createUserFirst
      if(this.createUserLast) params.last = this.createUserLast

      if(!this.createUserEmail){
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'warn',
          message: 'Email required'
        })
        //console.log("Email required")
        return
      }
      if(!this.createAccountType){
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'warn',
          message: 'Account Type required'
        })
        //console.log("Account Type required")
        return
      }
      if(this.group.maxMembers && this.group.members.length >= this.group.maxMembers){
        AmplifyStore.commit('set_flashMessage', {
          show: true,
          level: 'warn',
          message: 'Max members reached'
        })
        //console.log("Max members reached")
        return
      }
      console.log("CREATE USER PARAMS", params)
      AmplifyStore.dispatch('admin/createUser', params)
        .then(() => {
          console.log('running Lambda to add to CM')
          this.lambdaCampaignMonitor(params,"subscribe")
          this.createUserEmail = ''
          this.createUserFirst = ''
          this.createUserLast = ''
          this.createAccountType = 'user'
        })
    },
    deleteGroupMember: function(sub, email) {
      let params = {
        username: sub,
        email: email,
        group: this.slug,
        members: this.group.members
      }
      this.lambdaCampaignMonitor(params,"delete")
      AmplifyStore.dispatch('admin/deleteUser', params)
      AmplifyStore.dispatch('admin/removeGroupMember', params)
    },
    updateGroupStatus: function(status) {
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}UserGroups`,
        Key: {'slug': this.slug},
        UpdateExpression: 'set groupStatus = :val',
        ExpressionAttributeValues: { ":val": status }
      };

      this.docClient.update(params, function(err, data) {
        if (err) { console.log(err) }
        else {
          if(status === 'disabled') that.batchDisableUsers()
          if(status === 'active') that.batchEnableUsers()
          AmplifyStore.dispatch('admin/getGroupBySlug', that.slug)
        }
      });
    },
    batchDisableUsers: function() {
      let params = {
        userList: this.group.members
      }
      AmplifyStore.dispatch('admin/batchDisableUsers', params)
    },
    batchEnableUsers: function() {
      let params = {
        userList: this.group.members
      }
      AmplifyStore.dispatch('admin/batchEnableUsers', params)
    },
    percentDone: function(){
      return (this.createdUsersResults.length / this.dataFromCsv.length) * 100 + "%"
    },
    numberFailed: function(){
      return this.createdUsersResults.length - this.successCreationCount;
    },
    toggleResults: function(){
      this.showImportResults = !this.showImportResults;
    },
    resetImport: function(){
      this.createdUsersFromCsv = []
      this.createdUsersResults = []
      this.dataFromCsv = []
      this.errorDataFromCsv = []
      this.importCsvFile = false
      this.showImportResults = false
    },
    showUpload: function(){
      this.showModalUpload = true;
      this.dataFromCsv = []
      this.errorDataFromCsv = []
    },
    displaySubGroup: function(targ){
      this.subGroupDisplay = targ
    },
    showResource: function(file) {
      Storage
        .get(`resources/${file}`, {download: true})
        .then(res => {
          const newBlob = new Blob([res.Body], { "Content-Type": res.ContentType} )
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob, file);
              return;
          }
          const data = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = data;
          link.download = `${file}`;
          link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
          // link.click();

          setTimeout(function() {
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100)
        })
        .catch(err => console.log(err));
    },
    showLastLogin: function(person) {
      if (person.logins?.length > 0){
        let timeAgo = dayjs(person.logins[person.logins.length -1]).fromNow()
        return timeAgo
      }
      return ""
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    docClient() { return AmplifyStore.state.docClient },
    cognito() { return AmplifyStore.state.cognito },
    group() {return AmplifyStore.state.admin.allGroups.Item; },
    allSettings() { return AmplifyStore.state.admin.allSettings },
    groupUserData() { return AmplifyStore.state.admin.groupUserData },
    successCreationCount() { return this.createdUsersResults.filter(u => u.status === 200).length },
    allResources() { return AmplifyStore.state.admin.allResources },
    sortedMembers () {
      let copy = this.group.members.slice()
      if(this.groupUserData){
        copy = copy.map( m => {
          let data = this.groupUserData.find( u => u.user === m.sub)
          return {...m, ...data}
        })
      }
      
      return copy.sort(function(a, b) {
        if(!a.email || !b.email) return copy
        var textA = a.email.toLowerCase();
        var textB = b.email.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    sortedResources () {
      let filtered = Array.from(this.allResources).filter((r) => r.fileStatus === 'active').filter((r) => r.forGroups === 'all' || r.forGroups === this.slug || r.forGroups === this.group.settings)
      return filtered.sort((a, b) => (a.resourceName > b.resourceName) ? 1 : -1)
    }
  },
  watch: {
    'group.groupImage' : function(){
      //console.log('image updated')
      if(this.group.groupImage) this.groupImage = `groups/${this.group.slug}/${this.group.groupImage}`
      else this.groupImage = false
    },
    'group.groupDisplayName' : function(){
      if(this.group.groupDisplayName) this.groupDisplayName = this.group.groupDisplayName;
      else this.groupDisplayName = ''
    },
    'group.groupLinkTo' : function(){
      if(this.group.groupLinkTo) this.groupLinkTo = this.group.groupLinkTo;
      else this.groupLinkTo = ''
    }
    
  }
}
</script>
