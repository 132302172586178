<template>
  <div>
    <h2>Create a NEW layout preset</h2>
    <p class="info">Layout presets represent saved combinations of topic content, track display &amp; topic/track order. These can be assigned to individual groups. <strong>Tracks, Units and User Progress remain separate.</strong></p>
    <div class="newLayoutPreset">
        <div class="inputWrap flex-wrap">
          <div class="presetName">
            <label>Preset Name</label>
            <input type="text" v-model="createSettingsName" placeholder="Settings name"/>
          </div>
          <div class="duplicate">

            <label data-on="ON" data-off="OFF" class="toggle">
              Copy an existing preset?
              <div class="switch">
                <span class="subText">No</span>
                <input type="checkbox" id="checkbox" v-model="checked">
                <span class="track">
                  <span class="center"></span>
                </span>
                <span class="subText">Yes</span>
              </div>
            </label>

            <select v-model="selected" v-if="checked">
              <option value="null" disabled selected>Select preset to duplicate</option>
              <option v-for="s in allSettings" :value="s.config" v-bind:key="s.config">{{s.config}}</option>
            </select>
          </div>
        </div>
        <div class="buttonWrap">
          <button @click="createSettings()" :disabled="titleDuplicated() || !createSettingsName || creating">Create New Layout</button>
        </div>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import slugify from 'slugify'

export default {
  name: 'CreateSettings',
  data () {
    return {
      createSettingsName: '',
      checked: true,
      selected: null,
      creating: false
    }
  },
  methods: {
    createSettings: function() {
      this.creating = true
      // check for name
      if(!this.createSettingsName) return
      // check for name duplication
      if(this.titleDuplicated()) return
      // check that settings are selected if duplicate is selected
      if(this.checked && !this.selected) return
      let that = this
      let params = {
          TableName: `${this.ENV.tablePrefix}Settings`,
          Item: {
              "config": slugify(this.createSettingsName.toLowerCase()),
              "topics": [],
              "trackDisplay": []
          },
      }

      // If duplicate is checked and settings are selected copy values to params
      if(this.checked && this.selected){
        let dupSettings = this.allSettings.find(s => s.config === this.selected)
        params.Item.topics = dupSettings.topics
        params.Item.trackDisplay = dupSettings.trackDisplay
      }


      this.docClient.put(params, function(err, data) {
          if (err) {
            AmplifyStore.commit('set_flashMessage', {
              show: true,
              level: 'error',
              header: 'Error',
              message: 'Unable to create settings'
            })
            that.creating = false
          } else {
            AmplifyStore.dispatch('admin/getAllSettings')
            that.$emit('finishedCreating')
            AmplifyStore.commit('set_flashMessage', {
              show: true,
              level: 'success',
              header: 'Success!',
              message: `Settings "${that.createSettingsName}" created`
            })
            that.creating = false
          }
      });

    },
    titleDuplicated: function() {
      if(!this.allSettings) return true
      if(this.allSettings){
        let matchingTitle = this.allSettings.filter(t => {
          return t.config.toLowerCase() === this.createSettingsName.toLowerCase().trim()
        })[0]

        if(matchingTitle) return true
      }
      return false
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    user() { return AmplifyStore.state.user },
    allSettings() { return AmplifyStore.state.admin.allSettings }
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }
</style>
