<template>
  <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="layoutPresets" v-if="user.attributes['custom:role'] === 'admin'">
        <h3>Editing topics for <em>"{{currentSettings.config}}"</em> layout</h3>
        <div class="spacer"></div>
      <h3>Layout Presets:</h3>
      <select v-model="selected" v-on:change="updateCurrentSettings">
        <option v-for="s in allSettings" :value="s.config">{{s.config}}</option>
      </select>
    </div>

      <div class="adminBlock">
        <div class="adminHeader">
          <h2>Topics</h2>
          <!-- {{topics}} -->
          <router-link to="/admin/createtopic" v-slot="{href, route, navigate}">
                <button :href="href" @click="navigate" class="add">
                  Create Topic
                </button>
            </router-link>
        </div>

        <table class="linkTable unitList" v-if="sortedTopics">
          <thead>
            <tr>
              <th>Status:</th>
              <th>Name:</th>
              <!-- <th>Type:</th> -->
              <!-- <th>Created:</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="topic in sortedTopics" v-bind:key="topic.slug" @click="$router.push({ name: 'AdminUpdateTopic', params: {slug: topic.slug} })" v-bind:class="topic.disabled ? 'disabled' : ''">
              <td class="status">{{topic.disabled ? 'disabled' : ''}}</td>
              <td class="name">{{topic.title}}</td>
              <!-- <td class="type">{{topic.type}}</td> -->
              <!-- <td class="created">{{formatDateTime(topic.createdAt)}}</td> -->
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import AdminNav from '@/components/admin/components/AdminNav'


export default {
  name: 'Dashboard.Topics',
  components: {AdminNav},
  data () {
    return {
      selected: false
    }
  },
  created () {
    this.selected = this.currentSettings.config
  },
  methods: {
    updateCurrentSettings: function(e){
      let updatedSettings = this.allSettings.find( s => s.config === e.target.value)
      this.selected = e.target.value
      AmplifyStore.commit('admin/setSettings', updatedSettings)
      AmplifyStore.commit('private/setTrackDisplay', updatedSettings.trackDisplay)
      AmplifyStore.commit('private/setTopics', updatedSettings.topics)
    },
  },
  computed: {
    user() { return AmplifyStore.state.user },
    topics() { return AmplifyStore.state.private.topics },
    allSettings() { return AmplifyStore.state.admin.allSettings },
    currentSettings() { return AmplifyStore.state.admin.settings },
    sortedTopics () {
      let copy = Array.from(this.topics).slice()
      return copy.sort(function(a, b) {
        var textA = a.title.toLowerCase();
        var textB = b.title.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }

  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
