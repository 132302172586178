/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import privateModule from './private';
import adminModule from './admin';
import ENV from '@/config'
import { createStore } from 'vuex'

export const store = createStore({
    modules: {
        private: privateModule,
        admin: adminModule
    },
    state: {
        ENV: ENV,
        user: null,
        docClient: null,
        cognito: null,
        flash: {
            show: false,
            level: 'warn',
            message: ''
        }

    },
    mutations: {
        set_docClient(state, docClient) {
            state.docClient = docClient
        },
        set_cognito(state, cognito) {
            state.cognito = cognito
        },
        setUser(state, user) {
            state.user = user
        },
        set_flashMessage(state, flash) {
            state.flash = flash
        },
    }
})

export default store