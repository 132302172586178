<template>
  <div class="admin bodySpacer verticalSpacer">
      <AdminNav />
      <div class="adminBlock">
        <div class="adminHeader">
          <h2>Learning Units</h2>
          <router-link to="/admin/createunit" v-slot="{href, route, navigate}">
              <button :href="href" @click="navigate" class="add">
                Create Unit
              </button>
          </router-link>
        </div>

        <table class="linkTable unitList" v-if="allLearningUnits">
          <thead>
            <tr>
              <th>Status:</th>
              <th>Name:</th>
              <th>Used:</th>
              <th>Type:</th>
              <th>Created:</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit in activeUnits" v-bind:key="unit.unitId" @click="$router.push({ name: 'AdminUpdateUnit', params: {unitId: unit.unitId} })" v-bind:class="unit.unitDisabled ? 'disabled' : ''">
              <td class="status">{{unit.unitDisabled ? 'disabled' : ''}}</td>
              <td class="name">{{unit.title}}</td>
              <!-- For some reason there's always a null value in the "foundIn" array. This accounts for that. -->
              <td class="usage"><span v-if="unit.foundIn && unit.foundIn.values">{{unit.foundIn.values.length - 1}}</span></td>
              <td class="type">{{unit.type}}</td>
              <td class="created">{{formatDateTime(unit.createdAt)}}</td>
            </tr>
          </tbody>
          <tbody>
            <tr v-for="unit in inactiveUnits" v-bind:key="unit.unitId" @click="$router.push({ name: 'AdminUpdateUnit', params: {unitId: unit.unitId} })" v-bind:class="unit.unitDisabled ? 'disabled' : ''">
              <td class="status">{{unit.unitDisabled ? 'disabled' : ''}}</td>
              <td class="name">{{unit.title}}</td>
              <!-- For some reason there's always a null value in the "foundIn" array. This accounts for that. -->
              <td class="usage"><span v-if="unit.foundIn && unit.foundIn.values">{{unit.foundIn.values.length - 1}}</span></td>
              <td class="type">{{unit.type}}</td>
              <td class="created">{{formatDateTime(unit.createdAt)}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '@/store/store'
import AdminNav from '@/components/admin/components/AdminNav'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)


export default {
  name: 'Dashboard.Units',
  components: {AdminNav},
  data () {
    return {}
  },
  created () {
    // Initialize admin
    if(this.user.attributes["custom:role"] === "admin"){
      AmplifyStore.dispatch('admin/getAllLearningUnits')
    }

  },
  unmounted () {
    AmplifyStore.commit('admin/set_allLearningUnits', false)
  },
  methods: {
    formatDateTime(input){
      return dayjs(input).format("MM/DD/YY");
    }
  },
  computed: {
    user() { return AmplifyStore.state.user },
    allLearningUnits() { return AmplifyStore.state.admin.allLearningUnits },
    sortedUnits () {
      let copy = Array.from(this.allLearningUnits).slice()
      return copy.sort(function(a, b) {
        var textA = a.title.toLowerCase();
        var textB = b.title.toLowerCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    activeUnits () {
      return this.sortedUnits.filter( t => { return !t.unitDisabled })
    },
    inactiveUnits () {
      return this.sortedUnits.filter( t => { return t.unitDisabled })
    }
  }
}
</script>

<style scoped>
  .unit{
    text-align:left;
  }

  .unit.active{
    background-color: #999;
  }

  .error{
    border: 1px solid red;
    background-color: red
  }

  .adminHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
