<template>
  <div>
    <div class="headerBorder"></div>
    <div id="introSection" class="detailedTopics fullWidth">
      <section id="topicList" v-if="topics" class="bodySpacer verticalSpacer">
        <h6 v-if="userProgress && !userProgress.activeTracks.length" >Wondering where to start? Try one of these topics:</h6>
        <h6 v-else>We think you might like these topics:</h6>

        <div class="allTopics grid">
            <!-- <router-link tag="div" :to="{ name: 'UserProgress', params: {} }" v-if="userProgress && userProgress.activeTracks.length" class="topic featured">
              <h2><span>My Progress</span></h2>
              <div class="description">
                  <p>Pick up right where you left off! Here's a list of your recently accessed tracks.</p>
              </div>
              <h5><span>{{userProgress.activeTracks.length}} learning tracks</span></h5>
            </router-link> -->

            <router-link tag="div" :to="{ name: 'Topic', params: { slug: topic.slug} }" v-for="topic in featuredTopics" v-bind:key="topic.slug" class="topic featured">
              <h2><span>{{topic.title}}</span></h2>
              <div class="description" v-html="topic.content"></div>
              <h5><span>{{topic.tracks.length}} learning tracks</span></h5>
            </router-link>

            <router-link tag="div" :to="{ name: 'Topic', params: { slug: topic.slug} }" v-for="topic in topics" v-bind:key="topic.slug" class="topic" :style="`--track-primary: ${topic.secondaryColor}`">
              <h2><span>{{topic.title}}</span></h2>
              <div class="description" v-html="topic.content"></div>
              <h5><span>{{topic.tracks.length}} learning tracks</span></h5>
            </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '../store/store'

export default {
  name: 'AllTopics',
  components: { },
  data () {
    return {
      lastTrack: false,
      displayGrid: true,
      hasProgress: false,
    }
  },
  mounted () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    if(!this.userProgress) AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)

    if (this.user && this.user.attributes['custom:role']!='admin'){
      // this.$plausible.trackPageview()
    }
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    },
    getTopic(slug){
      this.$router.push(`/topic/${slug}`)
    },
  },
  computed: {
    user() { return AmplifyStore.state.user },
    tracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    topics() {
      if(AmplifyStore.state.private.topics){
        const list = AmplifyStore.state.private.topics.filter(topic => !topic.disabled && !topic.featured)
        return list
      }
      return []
    },
    featuredTopics() {
      if(AmplifyStore.state.private.topics){
        const list = AmplifyStore.state.private.topics.filter(topic => !topic.disabled && topic.featured)
        return list
      }
      return []
    },
    userProgress() { return AmplifyStore.state.private.userProgress },
    enabledTracks() {
      if(this.tracks && this.trackDisplay){
        let orderedTracks = this.trackDisplay.map( s => {
          return this.tracks.find( t => t.slug === s)
        });
        return orderedTracks.filter( t => !t.trackDisabled )
      }
    },
  }
}
</script>

<style scoped>
</style>
